import "./style.css";
import { defineComponent as g, openBlock as c, createElementBlock as p, Fragment as $, createBlock as y, resolveDynamicComponent as z, mergeProps as C, toHandlers as x, withCtx as W, renderSlot as A, resolveComponent as k, createTextVNode as wt, toDisplayString as ne, defineAsyncComponent as K, createVNode as B, renderList as N, createCommentVNode as S, h as Ue, createElementVNode as M, normalizeStyle as U, normalizeClass as J, markRaw as Tt } from "vue";
const It = /* @__PURE__ */ new Set(["area", "base", "br", "col", "embed", "hr", "img", "input", "keygen", "link", "meta", "param", "source", "track", "wbr"]), $t = (e) => typeof e == "string" && It.has(e.toLowerCase());
function V(e = {}, t, n) {
  const o = {};
  for (const r in e) {
    if (!e[r] || n && !r.startsWith(t))
      continue;
    const s = n ? r.replace(t, "") : r;
    o[s] = e[r];
  }
  return o;
}
const Rt = g({
  name: "dynamic-renderer",
  props: ["TagName", "attributes", "actionAttributes"],
  data() {
    return { isEmptyElement: $t };
  }
}), b = (e, t) => {
  const n = e.__vccOpts || e;
  for (const [o, r] of t)
    n[o] = r;
  return n;
};
function Et(e, t, n, o, r, s) {
  return e.isEmptyElement(e.TagName) ? (c(), y(z(e.TagName), C({ key: 1 }, e.attributes, x(e.actionAttributes)), null, 16)) : (c(), p($, { key: 0 }, [
    typeof e.TagName == "string" ? (c(), y(z(e.TagName), C({ key: 0 }, e.attributes, x(e.actionAttributes)), {
      default: W(() => [
        A(e.$slots, "default")
      ]),
      _: 3
    }, 16)) : (c(), y(z(e.TagName), C({ key: 1 }, e.attributes, x(e.actionAttributes)), {
      default: W(() => [
        A(e.$slots, "default")
      ]),
      _: 3
    }, 16))
  ], 64));
}
const he = /* @__PURE__ */ b(Rt, [["render", Et]]), Zs = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: he
}, Symbol.toStringTag, { value: "Module" })), R = "vue", _ = () => {
  switch (R) {
    case "react":
    case "reactNative":
    case "rsc":
      return "className";
    case "svelte":
    case "vue":
    case "solid":
    case "qwik":
    case "angular":
      return "class";
  }
}, Pt = g({
  name: "builder-button",
  components: { DynamicRenderer: he },
  props: [
    "attributes",
    "link",
    "openLinkInNewTab",
    "builderLinkComponent",
    "text"
  ],
  data() {
    return { filterAttrs: V };
  },
  methods: {
    attrs() {
      return {
        ...V(this.attributes, "v-on:", !1),
        [_()]: `${this.link ? "" : "builder-button"} ${this.attributes[_()] || ""}`,
        ...this.link ? {
          href: this.link,
          target: this.openLinkInNewTab ? "_blank" : void 0,
          role: "link"
        } : {
          role: "button"
        }
      };
    }
  }
});
function Bt(e, t, n, o, r, s) {
  const i = k("DynamicRenderer");
  return c(), y(i, {
    attributes: e.attrs(),
    TagName: e.link ? e.builderLinkComponent || "a" : "button",
    actionAttributes: e.filterAttrs(e.attributes, "v-on:", !0)
  }, {
    default: W(() => [
      wt(ne(e.text), 1)
    ]),
    _: 1
  }, 8, ["attributes", "TagName", "actionAttributes"]);
}
const At = /* @__PURE__ */ b(Pt, [["render", Bt]]), xt = Symbol(), de = {
  Builder: {
    content: null,
    context: {},
    localState: void 0,
    rootSetState() {
    },
    rootState: {},
    apiKey: null,
    apiVersion: void 0,
    componentInfos: {},
    inheritedStyles: {},
    BlocksWrapper: "div",
    BlocksWrapperProps: {},
    nonce: "",
    model: ""
  },
  key: xt
}, Vt = Symbol(), De = {
  Components: { registeredComponents: {} },
  key: Vt
}, Q = "[Builder.io]: ", P = {
  log: (...e) => console.log(Q, ...e),
  error: (...e) => console.error(Q, ...e),
  warn: (...e) => console.warn(Q, ...e),
  debug: (...e) => console.debug(Q, ...e)
}, Fe = (e, t, n) => {
  const o = String.prototype.split.call(t, /[,[\].]+?/).filter(Boolean).reduce((r, s) => r != null ? r[s] : r, e);
  return o === void 0 || o === e ? n : o;
};
function w() {
  return typeof window < "u" && typeof document < "u";
}
const Nt = (e) => {
  const t = {};
  return e.forEach((n, o) => {
    t[o] = n;
  }), t;
}, Le = (e) => e instanceof URLSearchParams ? Nt(e) : e, Me = (e) => typeof e == "string" ? e : e instanceof URLSearchParams ? e.toString() : new URLSearchParams(e).toString();
function Wt() {
  return w() && window.self !== window.top;
}
function E(e) {
  return Wt() && // accessing window.location.search is safe here because `isIframe()` is only `true` if we're in a browser.
  Me(e || window.location.search).indexOf("builder.frameEditing=") !== -1;
}
const Ot = () => {
  if (w()) {
    const e = new URL(location.href);
    return e.pathname === "" && (e.pathname = "/"), e;
  } else
    return console.warn("Cannot get location for tracking in non-browser environment"), null;
}, Ut = () => typeof navigator == "object" && navigator.userAgent || "", He = () => {
  const e = Ut(), t = {
    Android() {
      return e.match(/Android/i);
    },
    BlackBerry() {
      return e.match(/BlackBerry/i);
    },
    iOS() {
      return e.match(/iPhone|iPod/i);
    },
    Opera() {
      return e.match(/Opera Mini/i);
    },
    Windows() {
      return e.match(/IEMobile/i) || e.match(/WPDesktop/i);
    },
    any() {
      return t.Android() || t.BlackBerry() || t.iOS() || t.Opera() || t.Windows() || R === "reactNative";
    }
  }, n = e.match(/Tablet|iPad/i), o = Ot();
  return {
    urlPath: o == null ? void 0 : o.pathname,
    host: (o == null ? void 0 : o.host) || (o == null ? void 0 : o.hostname),
    device: n ? "tablet" : t.any() ? "mobile" : "desktop"
  };
}, Dt = ({
  builder: e,
  context: t,
  event: n,
  state: o
}) => Object.entries({
  state: o,
  Builder: e,
  // legacy
  builder: e,
  context: t,
  event: n
}), Ft = () => ({
  isEditing: E(),
  isBrowser: w(),
  isServer: !w(),
  getUserAttributes: () => He()
}), Lt = (e, {
  isExpression: t = !0
}) => /* we disable this for cases where we definitely don't want a return */ t && !(e.includes(";") || e.includes(" return ") || e.trim().startsWith("return ")) ? `return (${e});` : e;
function je({
  rootState: e,
  localState: t,
  rootSetState: n
}) {
  return new Proxy(e, {
    get: (o, r) => {
      if (t && r in t)
        return t[r];
      const s = o[r];
      return typeof s == "object" && s !== null ? je({
        rootState: s,
        localState: void 0,
        rootSetState: n ? (i) => {
          o[r] = i, n(o);
        } : void 0
      }) : s;
    },
    set: (o, r, s) => {
      if (t && r in t)
        throw new Error("Writing to local state is not allowed as it is read-only.");
      return o[r] = s, n == null || n(o), !0;
    }
  });
}
const $e = ({
  code: e,
  builder: t,
  context: n,
  event: o,
  localState: r,
  rootSetState: s,
  rootState: i
}) => {
  const l = Dt({
    builder: t,
    context: n,
    event: o,
    state: je({
      rootState: i,
      localState: r,
      rootSetState: s
    })
  });
  return new Function(...l.map(([a]) => a), e)(...l.map(([, a]) => a));
}, D = (e) => e != null;
function Mt() {
  var e;
  return typeof process < "u" && D((e = process == null ? void 0 : process.versions) == null ? void 0 : e.node);
}
const Ht = ({
  shouldLogWarning: e
}) => {
  var r;
  if (!Mt())
    return !1;
  const t = process.arch === "arm64", n = process.version.startsWith("v20"), o = (r = process.env.NODE_OPTIONS) == null ? void 0 : r.includes("--no-node-snapshot");
  return t && n && !o ? (e && P.log("Skipping usage of `isolated-vm` to avoid crashes in Node v20 on an arm64 machine.\n    If you would like to use the `isolated-vm` package on this machine, please provide the `NODE_OPTIONS=--no-node-snapshot` config to your Node process.\n    See https://github.com/BuilderIO/builder/blob/main/packages/sdks/README.md#node-v20--m1-macs-apple-silicon-support for more information.\n    "), !0) : !1;
}, jt = (e) => (w() || Ht({
  shouldLogWarning: !0
}), $e(e)), Kt = /^(return )?(\s*)?state(?<getPath>(\.\w+)+)(\s*);?$/, zt = /(\s)*var(\s)+_virtual_index(\s)*=(\s)*state(?<getPath>(\.\w+)+)(\s*);?(\s)*return(\s)*_virtual_index(\s)*/, qt = (e) => {
  var t, n, o, r, s, i;
  return ((o = (n = (t = Kt.exec(e.trim())) == null ? void 0 : t.groups) == null ? void 0 : n.getPath) == null ? void 0 : o.slice(1)) || ((i = (s = (r = zt.exec(e.trim())) == null ? void 0 : r.groups) == null ? void 0 : s.getPath) == null ? void 0 : i.slice(1));
};
function Y({
  code: e,
  context: t,
  localState: n,
  rootState: o,
  rootSetState: r,
  event: s,
  isExpression: i = !0
}) {
  if (e.trim() === "")
    return;
  const l = qt(e.trim());
  if (l)
    return Fe({
      ...o,
      ...n
    }, l);
  const a = {
    code: Lt(e, {
      isExpression: i
    }),
    builder: Ft(),
    context: t,
    event: s,
    rootSetState: r,
    rootState: o,
    localState: n
  };
  try {
    return jt(a);
  } catch (u) {
    P.error("Failed code evaluation: " + u.message, {
      code: e
    });
    return;
  }
}
function Jt(e, t) {
  var n;
  return {
    ...(n = e.component) == null ? void 0 : n.options,
    ...e.options,
    ...Gt(e, t)
  };
}
const Gt = (e, t) => {
  var n, o;
  if (((n = e.component) == null ? void 0 : n.name) === "Text" && ((o = e.component.options) != null && o.text) && typeof e.component.options.text == "string")
    return {
      ...e.component.options,
      text: e.component.options.text.replace(/{{([^}]+)}}/g, (r, s) => Y({
        code: s,
        context: t,
        localState: t.localState,
        rootState: t.rootState,
        rootSetState: t.rootSetState
      }))
    };
};
function Yt(e, ...t) {
  const n = Object.assign({}, e);
  for (const o of t)
    delete n[o];
  return n;
}
function ee(e, t, n = null, o = null, r = /* @__PURE__ */ new WeakSet()) {
  if (e == null || typeof e != "object") {
    t(e, (s) => {
      n !== null && o !== null && (n[o] = s);
    });
    return;
  }
  r.has(e) || (r.add(e), Array.isArray(e) ? e.forEach((s, i) => {
    t(s, (a) => {
      e[i] = a;
    }), ee(s, t, e, i, r);
  }) : Object.entries(e).forEach(([s, i]) => {
    t(i, (a) => {
      e[s] = a;
    }), ee(i, t, e, s, r);
  }));
}
function Ke(e) {
  return e && typeof e == "object" && e["@type"] === "@builder.io/core:LocalizedValue";
}
function Xt(e) {
  if (!e || !Object.getOwnPropertyNames(e).length)
    return !1;
  let t = !1;
  return ee(e, (n) => {
    if (Ke(n)) {
      t = !0;
      return;
    }
  }), t;
}
function Qt(e, t) {
  return !e || !Object.getOwnPropertyNames(e).length ? {} : (ee(e, (n, o) => {
    Ke(n) && o(n[t] ?? void 0);
  }), e);
}
function Zt(e, t) {
  var n, o;
  return (n = e.component) != null && n.options && Xt((o = e.component) == null ? void 0 : o.options) && (t || console.warn("[Builder.io] In order to use localized fields in Builder, you must pass a locale prop to the BuilderComponent or to options object while fetching the content to resolve localized fields. Learn more: https://www.builder.io/c/docs/localization-inline#targeting-and-inline-localization"), e.component.options = Qt(e.component.options, t ?? "Default")), e;
}
const fe = (e) => JSON.parse(JSON.stringify(e)), ze = (e, t, n) => {
  if (Object(e) !== e)
    return e;
  const o = Array.isArray(t) ? t : t.toString().match(/[^.[\]]+/g);
  return o.slice(0, -1).reduce((r, s, i) => Object(r[s]) === r[s] ? r[s] : r[s] = Math.abs(Number(o[i + 1])) >> 0 === +o[i + 1] ? [] : {}, e)[o[o.length - 1]] = n, e;
};
function ue(e) {
  if (e === null || typeof e != "object")
    return e;
  if (Array.isArray(e))
    return e.map((n) => ue(n));
  if (e["@type"] === "@builder.io/sdk:Element")
    return e;
  const t = {};
  for (const n in e)
    n !== "meta" && Object.prototype.hasOwnProperty.call(e, n) && (t[n] = ue(e[n]));
  return t;
}
const _t = ["svelte", "vue", "angular", "qwik", "solid"].includes(R), en = (e) => {
  if (_t) {
    const t = fe(e);
    return {
      ...t,
      properties: {
        ...t.properties
      },
      actions: {
        ...t.actions
      }
    };
  } else {
    const t = ue(Yt(e, "children", "meta"));
    return {
      ...t,
      properties: {
        ...t.properties
      },
      actions: {
        ...t.actions
      },
      children: e.children,
      meta: e.meta
    };
  }
}, tn = ({
  block: e,
  context: t,
  localState: n,
  rootState: o,
  rootSetState: r
}) => {
  if (!e.bindings)
    return e;
  const s = en(e);
  for (const i in e.bindings) {
    const l = e.bindings[i], a = Y({
      code: l,
      localState: n,
      rootState: o,
      rootSetState: r,
      context: t
    });
    ze(s, i, a);
  }
  return s;
};
function nn({
  block: e,
  context: t,
  localState: n,
  rootState: o,
  rootSetState: r
}) {
  let s = Zt(e, o.locale);
  return s = s, tn({
    block: s,
    localState: n,
    rootState: o,
    rootSetState: r,
    context: t
  });
}
function qe(e) {
  const t = e || (w() ? window.location.search : void 0);
  return t ? Me(t).indexOf("builder.preview=") !== -1 : !1;
}
const on = (e) => ({
  type: "builder.registerComponent",
  data: te(e)
}), rn = (e) => {
  const t = e.toString().trim(), n = /^[a-zA-Z0-9_]+\s*=>/i.test(t);
  return `return (${!t.startsWith("function") && !t.startsWith("async") && !t.startsWith("(") && !n ? "function " : ""}${t}).apply(this, arguments)`;
};
function te(e) {
  return JSON.parse(JSON.stringify(e, (t, n) => typeof n == "function" ? rn(n) : n));
}
const Re = {};
function sn(e, t) {
  e === "plugin" && (t = te(t));
  let n = Re[e];
  if (n || (n = Re[e] = []), n.push(t), w()) {
    const o = {
      type: "builder.register",
      data: {
        type: e,
        info: t
      }
    };
    try {
      parent.postMessage(o, "*"), parent !== window && window.postMessage(o, "*");
    } catch (r) {
      console.debug("Could not postmessage", r);
    }
  }
}
const Ee = {};
function _s(e) {
  if (w()) {
    Object.assign(Ee, e);
    const t = {
      type: "builder.settingsChange",
      data: Ee
    };
    parent.postMessage(t, "*");
  }
}
const Pe = "builder.", an = "options.", Je = (e) => {
  if (!e)
    return {};
  const t = Le(e), n = {};
  return Object.keys(t).forEach((o) => {
    if (o.startsWith(Pe)) {
      const r = o.replace(Pe, "").replace(an, "");
      n[r] = t[o];
    }
  }), n;
}, ln = () => {
  if (!w())
    return {};
  const e = new URLSearchParams(window.location.search);
  return Je(e);
}, Ge = "4.0.4", Ye = () => ({
  "X-Builder-SDK": R,
  "X-Builder-SDK-GEN": "2",
  "X-Builder-SDK-Version": Ge
}), cn = (e) => {
  if (e === "localhost" || e === "127.0.0.1")
    return e;
  const t = e.split(".");
  return t.length > 2 ? t.slice(1).join(".") : e;
}, oe = ({
  name: e,
  canTrack: t
}) => {
  var n;
  try {
    return t ? (n = document.cookie.split("; ").find((o) => o.startsWith(`${e}=`))) == null ? void 0 : n.split("=")[1] : void 0;
  } catch (o) {
    P.warn("[COOKIE] GET error: ", (o == null ? void 0 : o.message) || o);
    return;
  }
}, Xe = async (e) => oe(e), dn = (e) => e.map(([t, n]) => n ? `${t}=${n}` : t).filter(D).join("; "), un = [["secure", ""], ["SameSite", "None"]], pn = ({
  name: e,
  value: t,
  expires: n
}) => {
  const r = (w() ? location.protocol === "https:" : !0) ? un : [[]], s = n ? [["expires", n.toUTCString()]] : [[]], i = [[e, t], ...s, ["path", "/"], ["domain", cn(window.location.hostname)], ...r];
  return dn(i);
}, ge = async ({
  name: e,
  value: t,
  expires: n,
  canTrack: o
}) => {
  try {
    if (!o)
      return;
    const r = pn({
      name: e,
      value: t,
      expires: n
    });
    document.cookie = r;
  } catch (r) {
    P.warn("[COOKIE] SET error: ", (r == null ? void 0 : r.message) || r);
  }
};
function mn() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(e) {
    const t = Math.random() * 16 | 0;
    return (e == "x" ? t : t & 3 | 8).toString(16);
  });
}
function Qe() {
  return mn().replace(/-/g, "");
}
const Ze = "builderSessionId", hn = async ({
  canTrack: e
}) => {
  if (!e)
    return;
  const t = await Xe({
    name: Ze,
    canTrack: e
  });
  if (D(t))
    return t;
  {
    const n = fn();
    return gn({
      id: n,
      canTrack: e
    }), n;
  }
}, fn = () => Qe(), gn = ({
  id: e,
  canTrack: t
}) => ge({
  name: Ze,
  value: e,
  canTrack: t
}), _e = () => w() && typeof localStorage < "u" ? localStorage : void 0, bn = ({
  key: e,
  canTrack: t
}) => {
  var n;
  try {
    return t ? (n = _e()) == null ? void 0 : n.getItem(e) : void 0;
  } catch (o) {
    console.debug("[LocalStorage] GET error: ", o);
    return;
  }
}, yn = ({
  key: e,
  canTrack: t,
  value: n
}) => {
  var o;
  try {
    t && ((o = _e()) == null || o.setItem(e, n));
  } catch (r) {
    console.debug("[LocalStorage] SET error: ", r);
  }
}, et = "builderVisitorId", kn = ({
  canTrack: e
}) => {
  if (!e)
    return;
  const t = bn({
    key: et,
    canTrack: e
  });
  if (D(t))
    return t;
  {
    const n = vn();
    return Sn({
      id: n,
      canTrack: e
    }), n;
  }
}, vn = () => Qe(), Sn = ({
  id: e,
  canTrack: t
}) => yn({
  key: et,
  value: e,
  canTrack: t
});
function be(e) {
  var t;
  typeof process < "u" && ((t = process.env) != null && t.DEBUG) && String(process.env.DEBUG) == "true" && P.log(e);
}
const Cn = async ({
  canTrack: e
}) => {
  if (!e)
    return {
      visitorId: void 0,
      sessionId: void 0
    };
  const t = await hn({
    canTrack: e
  }), n = kn({
    canTrack: e
  });
  return {
    sessionId: t,
    visitorId: n
  };
}, wn = async ({
  type: e,
  canTrack: t,
  apiKey: n,
  metadata: o,
  ...r
}) => ({
  type: e,
  data: {
    ...r,
    metadata: {
      url: location.href,
      ...o
    },
    ...await Cn({
      canTrack: t
    }),
    userAttributes: He(),
    ownerId: n
  }
});
async function pe({
  apiHost: e,
  ...t
}) {
  if (!t.apiKey) {
    P.error("Missing API key for track call. Please provide your API key.");
    return;
  }
  if (!t.canTrack || E() || !(w() || R === "reactNative"))
    return;
  const o = `${e || "https://cdn.builder.io"}/api/v1/track`;
  return be(o), fetch(o, {
    method: "POST",
    body: JSON.stringify({
      events: [await wn(t)]
    }),
    headers: {
      "content-type": "application/json",
      ...Ye()
    },
    mode: "cors"
  }).catch((r) => {
    console.error("Failed to track: ", r);
  });
}
const ei = (e) => pe({
  ...e,
  canTrack: !0
}), Tn = ["*.beta.builder.io", "beta.builder.io", "builder.io", "localhost", "qa.builder.io"];
function tt(e, t) {
  if (!t.origin.startsWith("http") && !t.origin.startsWith("https"))
    return !1;
  const n = new URL(t.origin), o = n.hostname;
  return (e || Tn).findIndex((r) => r.startsWith("*.") ? o.endsWith(r.slice(1)) : r === o) > -1;
}
const In = () => {
  sn("insertMenu", {
    name: "_default",
    default: !0,
    items: [{
      name: "Box"
    }, {
      name: "Text"
    }, {
      name: "Image"
    }, {
      name: "Columns"
    }, {
      name: "Core:Section"
    }, {
      name: "Core:Button"
    }, {
      name: "Embed"
    }, {
      name: "Custom Code"
    }]
  });
};
let Be = !1;
const nt = (e) => {
  var t, n;
  Be || (Be = !0, w() && ((t = window.parent) == null || t.postMessage({
    type: "builder.sdkInfo",
    data: {
      target: R,
      version: Ge,
      supportsPatchUpdates: !1,
      // Supports builder-model="..." attribute which is needed to
      // scope our '+ add block' button styling
      supportsAddBlockScoping: !0,
      supportsCustomBreakpoints: !0,
      modelName: e.modelName,
      apiKey: e.apiKey,
      supportsXSmallBreakpoint: !0,
      blockLevelPersonalization: !0
    }
  }, "*"), (n = window.parent) == null || n.postMessage({
    type: "builder.updateContent",
    data: {
      options: e
    }
  }, "*"), window.addEventListener("message", (o) => {
    var s, i;
    if (!tt(e.trustedHosts, o))
      return;
    const {
      data: r
    } = o;
    if (r != null && r.type)
      switch (r.type) {
        case "builder.evaluate": {
          const l = r.data.text, a = r.data.arguments || [], u = r.data.id, h = new Function(l);
          let m, d = null;
          try {
            m = h.apply(null, a);
          } catch (f) {
            d = f;
          }
          d ? (s = window.parent) == null || s.postMessage({
            type: "builder.evaluateError",
            data: {
              id: u,
              error: d.message
            }
          }, "*") : m && typeof m.then == "function" ? m.then((f) => {
            var v;
            (v = window.parent) == null || v.postMessage({
              type: "builder.evaluateResult",
              data: {
                id: u,
                result: f
              }
            }, "*");
          }).catch(console.error) : (i = window.parent) == null || i.postMessage({
            type: "builder.evaluateResult",
            data: {
              result: m,
              id: u
            }
          }, "*");
          break;
        }
      }
  })));
}, ot = ({
  model: e,
  trustedHosts: t,
  callbacks: n
}) => (o) => {
  if (!tt(t, o))
    return;
  const {
    data: r
  } = o;
  if (r)
    switch (r.type) {
      case "builder.configureSdk": {
        n.configureSdk(r.data);
        break;
      }
      case "builder.triggerAnimation": {
        n.animation(r.data);
        break;
      }
      case "builder.resetState": {
        const s = r.data, i = s.model, l = s == null ? void 0 : s.state;
        i === e && l && n.stateUpdate(l);
        break;
      }
      case "builder.contentUpdate": {
        const s = r.data, i = s.key || s.alias || s.entry || s.modelName, l = s.data;
        i === e && n.contentUpdate(l);
        break;
      }
    }
}, ti = ({
  model: e,
  apiKey: t,
  callback: n,
  trustedHosts: o
}) => {
  if (!w)
    return P.warn("`subscribeToEditor` only works in the browser. It currently seems to be running on the server."), () => {
    };
  nt({
    modelName: e,
    apiKey: t
  });
  const r = ot({
    callbacks: {
      contentUpdate: n,
      animation: () => {
      },
      configureSdk: () => {
      },
      stateUpdate: () => {
      }
    },
    model: e,
    trustedHosts: o
  });
  return window.addEventListener("message", r), () => {
    window.removeEventListener("message", r);
  };
}, $n = "builder.tests", ye = (e) => `${$n}.${e}`, Rn = ({
  contentId: e
}) => Xe({
  name: ye(e),
  canTrack: !0
}), En = ({
  contentId: e
}) => oe({
  name: ye(e),
  canTrack: !0
}), Pn = ({
  contentId: e,
  value: t
}) => ge({
  name: ye(e),
  value: t,
  canTrack: !0
}), rt = (e) => D(e.id) && D(e.variations) && Object.keys(e.variations).length > 0, Bn = ({
  id: e,
  variations: t
}) => {
  var r;
  let n = 0;
  const o = Math.random();
  for (const s in t) {
    const i = (r = t[s]) == null ? void 0 : r.testRatio;
    if (n += i, o < n)
      return s;
  }
  return e;
}, st = (e) => {
  const t = Bn(e);
  return Pn({
    contentId: e.id,
    value: t
  }).catch((n) => {
    P.error("could not store A/B test variation: ", n);
  }), t;
}, it = ({
  item: e,
  testGroupId: t
}) => {
  const n = e.variations[t];
  return t === e.id || // handle edge-case where `testGroupId` points to non-existing variation
  !n ? {
    testVariationId: e.id,
    testVariationName: "Default"
  } : {
    data: n.data,
    testVariationId: n.id,
    testVariationName: n.name || (n.id === e.id ? "Default" : "")
  };
}, An = ({
  item: e,
  canTrack: t
}) => {
  if (!t)
    return e;
  if (!e)
    return;
  if (!rt(e))
    return e;
  const n = En({
    contentId: e.id
  }) || st({
    variations: e.variations,
    id: e.id
  }), o = it({
    item: e,
    testGroupId: n
  });
  return {
    ...e,
    ...o
  };
}, xn = async ({
  item: e,
  canTrack: t
}) => {
  if (!t || !rt(e))
    return e;
  const o = await Rn({
    contentId: e.id
  }) || st({
    variations: e.variations,
    id: e.id
  }), r = it({
    item: e,
    testGroupId: o
  });
  return {
    ...e,
    ...r
  };
}, Ae = "builder.userAttributes";
function Vn() {
  let e = !0;
  const t = /* @__PURE__ */ new Set();
  return {
    setUserAttributes(n) {
      if (!w())
        return;
      const o = {
        ...this.getUserAttributes(),
        ...n
      };
      ge({
        name: Ae,
        value: JSON.stringify(o),
        canTrack: e
      }), t.forEach((r) => r(o));
    },
    getUserAttributes() {
      return w() ? JSON.parse(oe({
        name: Ae,
        canTrack: e
      }) || "{}") : {};
    },
    subscribeOnUserAttributesChange(n) {
      return t.add(n), () => {
        t.delete(n);
      };
    },
    setCanTrack(n) {
      e = n;
    }
  };
}
const at = Vn(), ni = (e) => {
  at.setUserAttributes(e);
}, G = (e) => {
  const t = D(e) ? e : !0;
  return at.setCanTrack(t), t;
};
function Nn() {
  return typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : globalThis;
}
function Wn() {
  const e = Nn().fetch;
  if (typeof e > "u")
    throw console.warn(`Builder SDK could not find a global fetch function. Make sure you have a polyfill for fetch in your project. 
      For more information, read https://github.com/BuilderIO/this-package-uses-fetch`), new Error("Builder SDK could not find a global `fetch` function");
  return e;
}
const On = Wn();
function me(e, t = null, n = ".") {
  return Object.keys(e).reduce((o, r) => {
    const s = e[r], i = [t, r].filter(Boolean).join(n);
    return [typeof s == "object", s !== null, !(Array.isArray(s) && s.length === 0)].every(Boolean) ? {
      ...o,
      ...me(s, i, n)
    } : {
      ...o,
      [i]: s
    };
  }, {});
}
function lt(e, t, n = {}) {
  for (const o in e) {
    const r = e[o], s = t ? t + "." + o : o;
    r && typeof r == "object" && !Array.isArray(r) && !Object.keys(r).find((i) => i.startsWith("$")) ? lt(r, s, n) : n[s] = r;
  }
  return n;
}
function Un(e) {
  const t = {};
  for (const n in e) {
    const o = n.split(".");
    let r = t;
    for (let s = 0; s < o.length; s++) {
      const i = o[s];
      s === o.length - 1 ? r[i] = e[n] : (r[i] = r[i] || {}, r = r[i]);
    }
  }
  return t;
}
const Dn = "v3", xe = (e) => typeof e == "number" && !isNaN(e) && e >= 0, ke = (e) => {
  const {
    limit: t = 30,
    userAttributes: n,
    query: o,
    model: r,
    apiKey: s,
    enrich: i,
    locale: l,
    apiVersion: a = Dn,
    fields: u,
    omit: h,
    offset: m,
    cacheSeconds: d,
    staleCacheSeconds: f,
    sort: v,
    includeUnpublished: T,
    apiHost: F
  } = e;
  if (!s)
    throw new Error("Missing API key");
  if (!["v3"].includes(a))
    throw new Error(`Invalid apiVersion: expected 'v3', received '${a}'`);
  const St = t !== 1, Ct = F || "https://cdn.builder.io", I = new URL(`${Ct}/api/${a}/content/${r}`);
  I.searchParams.set("apiKey", s), I.searchParams.set("limit", String(t)), I.searchParams.set("noTraverse", String(St)), I.searchParams.set("includeRefs", String(!0));
  const re = l || (n == null ? void 0 : n.locale);
  let L = n || {};
  if (re && (I.searchParams.set("locale", re), L = {
    locale: re,
    ...L
  }), i && I.searchParams.set("enrich", String(i)), I.searchParams.set("omit", h || "meta.componentsUsed"), u && I.searchParams.set("fields", u), Number.isFinite(m) && m > -1 && I.searchParams.set("offset", String(Math.floor(m))), typeof T == "boolean" && I.searchParams.set("includeUnpublished", String(T)), d && xe(d) && I.searchParams.set("cacheSeconds", String(d)), f && xe(f) && I.searchParams.set("staleCacheSeconds", String(f)), v) {
    const O = me({
      sort: v
    });
    for (const j in O)
      I.searchParams.set(j, JSON.stringify(O[j]));
  }
  const Te = {
    ...ln(),
    ...Le(e.options || {})
  };
  L = {
    ...L,
    ...Ln(Te)
  };
  const Ie = me(Te);
  for (const O in Ie)
    I.searchParams.set(O, String(Ie[O]));
  if (Object.keys(L).length > 0 && I.searchParams.set("userAttributes", JSON.stringify(L)), o) {
    const O = lt({
      query: o
    });
    for (const j in O)
      I.searchParams.set(j, JSON.stringify(O[j]));
  }
  return I;
}, Fn = (e) => {
  const t = {};
  for (const n in e)
    n.startsWith("userAttributes.") && (t[n] = e[n], delete e[n]);
  return t;
}, Ln = (e) => {
  if (w() && e.preview === "BUILDER_STUDIO") {
    e["userAttributes.urlPath"] = window.location.pathname, e["userAttributes.host"] = window.location.host;
    const t = Fn(e), {
      userAttributes: n
    } = Un(t);
    return n;
  }
  return {};
}, Mn = (e) => "results" in e;
async function ve(e) {
  const t = await Kn({
    ...e,
    limit: 1
  });
  return t && t[0] || null;
}
const Hn = async (e) => {
  var i;
  const t = ke(e), n = e.fetch ?? On, o = {
    ...e.fetchOptions,
    headers: {
      ...(i = e.fetchOptions) == null ? void 0 : i.headers,
      ...Ye()
    }
  };
  return await (await n(t.href, o)).json();
}, jn = async (e, t, n = ke(e)) => {
  const o = G(e.canTrack);
  if (n.search.includes("preview="), !o || !(w() || R === "reactNative"))
    return t.results;
  try {
    const r = [];
    for (const s of t.results)
      r.push(await xn({
        item: s,
        canTrack: o
      }));
    t.results = r;
  } catch (r) {
    P.error("Could not process A/B tests. ", r);
  }
  return t.results;
};
async function Kn(e) {
  const t = ke(e), n = await Hn(e);
  if (!Mn(n))
    throw P.error("Error fetching data. ", {
      url: t,
      content: n,
      options: e
    }), n;
  return jn(e, n);
}
const oi = async (e) => {
  var o, r, s;
  const t = e.path || ((o = e.url) == null ? void 0 : o.pathname) || ((r = e.userAttributes) == null ? void 0 : r.urlPath), n = {
    ...e,
    apiKey: e.apiKey,
    model: e.model || "page",
    userAttributes: {
      ...e.userAttributes,
      ...t ? {
        urlPath: t
      } : {}
    },
    options: Je(e.searchParams || ((s = e.url) == null ? void 0 : s.searchParams) || e.options)
  };
  return {
    apiKey: n.apiKey,
    model: n.model,
    content: await ve(n)
  };
}, Se = (e) => e ? e.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase() : "";
function zn(e, t, n = {}) {
  let o, r, s, i = null, l = 0;
  const a = function() {
    l = n.leading === !1 ? 0 : Date.now(), i = null, s = e.apply(o, r), i || (o = r = null);
  };
  return function() {
    const u = Date.now();
    !l && n.leading === !1 && (l = u);
    const h = t - (u - l);
    return o = this, r = arguments, h <= 0 || h > t ? (i && (clearTimeout(i), i = null), l = u, s = e.apply(o, r), i || (o = r = null)) : !i && n.trailing !== !1 && (i = setTimeout(a, h)), s;
  };
}
function q(e, ...t) {
  const n = Object(e);
  for (let o = 1; o < arguments.length; o++) {
    const r = arguments[o];
    if (r != null)
      for (const s in r)
        Object.prototype.hasOwnProperty.call(r, s) && (n[s] = r[s]);
  }
  return n;
}
function qn(e) {
  for (const t of e)
    switch (t.trigger) {
      case "pageLoad":
        ut(t);
        break;
      case "scrollInView":
        Gn(t);
        break;
    }
}
function ct(e) {
  console.warn(`Cannot animate element: element with ID ${e} not found!`);
}
function dt(e, t) {
  const n = Jn(e), o = getComputedStyle(t), r = e.steps[0].styles, s = e.steps[e.steps.length - 1].styles, i = [r, s];
  for (const l of i)
    for (const a of n)
      a in l || (l[a] = o[a]);
}
function Jn(e) {
  const t = [];
  for (const n of e.steps)
    for (const o in n.styles)
      t.indexOf(o) === -1 && t.push(o);
  return t;
}
function ut(e) {
  const t = Array.prototype.slice.call(document.getElementsByClassName(e.elementId || e.id || ""));
  if (!t.length) {
    ct(e.elementId || e.id || "");
    return;
  }
  Array.from(t).forEach((n) => {
    dt(e, n), n.style.transition = "none", n.style.transitionDelay = "0", q(n.style, e.steps[0].styles), setTimeout(() => {
      n.style.transition = `all ${e.duration}s ${Se(e.easing)}`, e.delay && (n.style.transitionDelay = e.delay + "s"), q(n.style, e.steps[1].styles), setTimeout(() => {
        n.style.transition = "", n.style.transitionDelay = "";
      }, (e.delay || 0) * 1e3 + e.duration * 1e3 + 100);
    });
  });
}
function Gn(e) {
  const t = Array.prototype.slice.call(document.getElementsByClassName(e.elementId || e.id || ""));
  if (!t.length) {
    ct(e.elementId || e.id || "");
    return;
  }
  Array.from(t).forEach((n) => {
    dt(e, n);
    let o = !1, r = !1;
    function s() {
      !o && l(n) ? (o = !0, r = !0, setTimeout(() => {
        q(n.style, e.steps[1].styles), e.repeat || document.removeEventListener("scroll", i), setTimeout(() => {
          r = !1, e.repeat || (n.style.transition = "", n.style.transitionDelay = "");
        }, (e.duration + (e.delay || 0)) * 1e3 + 100);
      })) : e.repeat && o && !r && !l(n) && (o = !1, q(n.style, e.steps[0].styles));
    }
    const i = zn(s, 200, {
      leading: !1
    });
    function l(h) {
      const m = h.getBoundingClientRect(), d = window.innerHeight, v = (e.thresholdPercent || 0) / 100 * d;
      return m.bottom > v && m.top < d - v;
    }
    const a = e.steps[0].styles;
    function u() {
      q(n.style, a);
    }
    u(), setTimeout(() => {
      n.style.transition = `all ${e.duration}s ${Se(e.easing)}`, e.delay && (n.style.transitionDelay = e.delay + "s");
    }), document.addEventListener("scroll", i, {
      capture: !0,
      passive: !0
    }), s();
  });
}
const pt = (e) => Object.entries(e).map(([n, o]) => {
  if (typeof o == "string")
    return `${Se(n)}: ${o};`;
}).filter(D), Yn = (e) => pt(e).join(`
`), ri = ({
  mediaQuery: e,
  className: t,
  styles: n
}) => {
  const o = `.${t} {
    ${Yn(n)}
  }`;
  return e ? `${e} {
      ${o}
    }` : o;
};
function Xn({
  style: e
}) {
  return e;
}
const si = ({
  block: e,
  context: t
}) => mt(Xn({
  style: e.style || {},
  context: t,
  block: e
}));
function mt(e) {
  switch (R) {
    case "svelte":
    case "vue":
    case "solid":
    case "angular":
      return pt(e).join(" ");
    case "qwik":
    case "reactNative":
    case "react":
    case "rsc":
      return e;
  }
}
const ht = (e, t) => e ? t ? e.models && e.models.length > 0 && !e.models.includes(t) : !1 : !0, Qn = ({
  block: e,
  registeredComponents: t,
  model: n
}) => {
  var s;
  const o = (s = e.component) == null ? void 0 : s.name;
  if (!o)
    return null;
  const r = t[o];
  if (!r || ht(r, n)) {
    console.warn(`
      Could not find a registered component named "${o}". 
      If you registered it, is the file that registered it imported by the file that needs to render it?`);
    return;
  } else
    return r;
}, Zn = ({
  block: e,
  context: t
}) => {
  const {
    repeat: n,
    ...o
  } = e;
  if (!(n != null && n.collection))
    return;
  const r = Y({
    code: n.collection,
    localState: t.localState,
    rootState: t.rootState,
    rootSetState: t.rootSetState,
    context: t.context
  });
  if (!Array.isArray(r))
    return;
  const s = n.collection.split(".").pop(), i = n.itemName || (s ? s + "Item" : "item");
  return r.map((a, u) => ({
    context: {
      ...t,
      localState: {
        ...t.localState,
        $index: u,
        $item: a,
        [i]: a,
        [`$${i}Index`]: u
      }
    },
    block: o
  }));
}, _n = (e, t) => {
  var n;
  return (n = e == null ? void 0 : e.shouldReceiveBuilderProps) != null && n.builderLinkComponent ? {
    builderLinkComponent: t
  } : {};
}, eo = (e, t, n) => {
  var o;
  return (o = e == null ? void 0 : e.shouldReceiveBuilderProps) != null && o.builderComponents ? {
    builderComponents: Object.fromEntries(Object.entries(t).filter(([s, i]) => !ht(i, n)))
  } : {};
}, to = (e, t) => {
  var n;
  return (n = e == null ? void 0 : e.shouldReceiveBuilderProps) != null && n.builderBlock ? {
    builderBlock: t
  } : {};
}, no = (e, t) => {
  var n;
  return (n = e == null ? void 0 : e.shouldReceiveBuilderProps) != null && n.builderContext ? {
    builderContext: t
  } : {};
}, oo = () => Promise.resolve().then(() => yo).then((e) => e.default).catch((e) => {
  throw console.error(
    "Error while attempting to dynamically import component DynamicDiv at ../dynamic-div.vue",
    e
  ), e;
}), ro = () => import("./block-styles-MXm48vmz.js").then((e) => e.default).catch((e) => {
  throw console.error(
    "Error while attempting to dynamically import component BlockStyles at ./components/block-styles.vue",
    e
  ), e;
}), so = () => import("./block-wrapper-4qw8AGWG.js").then((e) => e.default).catch((e) => {
  throw console.error(
    "Error while attempting to dynamically import component BlockWrapper at ./components/block-wrapper.vue",
    e
  ), e;
}), io = () => import("./component-ref-qKfSMika.js").then((e) => e.default).catch((e) => {
  throw console.error(
    "Error while attempting to dynamically import component ComponentRef at ./components/component-ref/component-ref.vue",
    e
  ), e;
}), ao = () => import("./repeated-block-kQ_jnUM3.js").then((e) => e.default).catch((e) => {
  throw console.error(
    "Error while attempting to dynamically import component RepeatedBlock at ./components/repeated-block.vue",
    e
  ), e;
}), lo = g({
  name: "block",
  components: {
    BlockStyles: K(ro),
    RepeatedBlock: K(ao),
    ComponentRef: K(io),
    BlockWrapper: K(so),
    DynamicDiv: K(oo)
  },
  props: ["block", "context", "registeredComponents", "linkComponent"],
  data() {
    return {
      _processedBlock: {
        value: null,
        update: !1
      }
    };
  },
  mounted() {
    const e = this.processedBlock.id, t = this.processedBlock.animations;
    t && e && qn(
      t.map((n) => ({
        ...n,
        elementId: e
      }))
    );
  },
  updated() {
  },
  computed: {
    repeatItem() {
      return Zn({
        block: this.block,
        context: this.context
      });
    },
    processedBlock() {
      var t;
      return (t = this.block.repeat) != null && t.collection ? this.block : nn({
        block: this.block,
        localState: this.context.localState,
        rootState: this.context.rootState,
        rootSetState: this.context.rootSetState,
        context: this.context.context
      });
    },
    blockComponent() {
      return Qn({
        block: this.processedBlock,
        registeredComponents: this.registeredComponents,
        model: this.context.model
      });
    },
    Tag() {
      var t;
      return this.block.tagName === "a" || ((t = this.processedBlock.properties) == null ? void 0 : t.href) || this.processedBlock.href ? this.linkComponent || "a" : this.block.tagName || "div";
    },
    canShowBlock() {
      var n, o;
      if ((n = this.block.repeat) != null && n.collection)
        return !!((o = this.repeatItem) != null && o.length);
      const e = "hide" in this.processedBlock ? this.processedBlock.hide : !1;
      return ("show" in this.processedBlock ? this.processedBlock.show : !0) && !e;
    },
    childrenWithoutParentComponent() {
      var t;
      return !((t = this.blockComponent) != null && t.component) && !this.repeatItem ? this.processedBlock.children ?? [] : [];
    },
    componentRefProps() {
      var e, t, n;
      return {
        blockChildren: this.processedBlock.children ?? [],
        componentRef: (e = this.blockComponent) == null ? void 0 : e.component,
        componentOptions: {
          ...Jt(this.processedBlock, this.context),
          ...to(this.blockComponent, this.processedBlock),
          ...no(this.blockComponent, this.context),
          ..._n(this.blockComponent, this.linkComponent),
          ...eo(
            this.blockComponent,
            this.registeredComponents,
            this.context.model
          )
        },
        context: this.context,
        linkComponent: this.linkComponent,
        registeredComponents: this.registeredComponents,
        builderBlock: this.processedBlock,
        includeBlockProps: ((t = this.blockComponent) == null ? void 0 : t.noWrap) === !0,
        isInteractive: !((n = this.blockComponent) != null && n.isRSC && R === "rsc")
      };
    }
  }
});
function co(e, t, n, o, r, s) {
  var m;
  const i = k("BlockStyles"), l = k("ComponentRef"), a = k("Block", !0), u = k("BlockWrapper"), h = k("RepeatedBlock");
  return e.canShowBlock ? (c(), p($, { key: 0 }, [
    B(i, {
      block: e.processedBlock,
      context: e.context
    }, null, 8, ["block", "context"]),
    (m = e.blockComponent) != null && m.noWrap ? (c(), p($, { key: 1 }, [
      e.repeatItem ? (c(!0), p($, { key: 1 }, N(e.repeatItem, (d, f) => (c(), y(h, {
        key: f,
        repeatContext: d.context,
        block: d.block,
        registeredComponents: e.registeredComponents,
        linkComponent: e.linkComponent
      }, null, 8, ["repeatContext", "block", "registeredComponents", "linkComponent"]))), 128)) : (c(), y(l, {
        key: 0,
        componentRef: e.componentRefProps.componentRef,
        componentOptions: e.componentRefProps.componentOptions,
        blockChildren: e.componentRefProps.blockChildren,
        context: e.componentRefProps.context,
        registeredComponents: e.componentRefProps.registeredComponents,
        linkComponent: e.componentRefProps.linkComponent,
        builderBlock: e.componentRefProps.builderBlock,
        includeBlockProps: e.componentRefProps.includeBlockProps,
        isInteractive: e.componentRefProps.isInteractive
      }, null, 8, ["componentRef", "componentOptions", "blockChildren", "context", "registeredComponents", "linkComponent", "builderBlock", "includeBlockProps", "isInteractive"]))
    ], 64)) : (c(), p($, { key: 0 }, [
      e.repeatItem ? (c(!0), p($, { key: 1 }, N(e.repeatItem, (d, f) => (c(), y(h, {
        key: f,
        repeatContext: d.context,
        block: d.block,
        registeredComponents: e.registeredComponents,
        linkComponent: e.linkComponent
      }, null, 8, ["repeatContext", "block", "registeredComponents", "linkComponent"]))), 128)) : (c(), y(u, {
        key: 0,
        Wrapper: e.Tag,
        block: e.processedBlock,
        context: e.context
      }, {
        default: W(() => [
          B(l, {
            componentRef: e.componentRefProps.componentRef,
            componentOptions: e.componentRefProps.componentOptions,
            blockChildren: e.componentRefProps.blockChildren,
            context: e.componentRefProps.context,
            registeredComponents: e.componentRefProps.registeredComponents,
            linkComponent: e.componentRefProps.linkComponent,
            builderBlock: e.componentRefProps.builderBlock,
            includeBlockProps: e.componentRefProps.includeBlockProps,
            isInteractive: e.componentRefProps.isInteractive
          }, null, 8, ["componentRef", "componentOptions", "blockChildren", "context", "registeredComponents", "linkComponent", "builderBlock", "includeBlockProps", "isInteractive"]),
          (c(!0), p($, null, N(e.childrenWithoutParentComponent, (d, f) => (c(), y(a, {
            key: d.id,
            block: d,
            registeredComponents: e.registeredComponents,
            linkComponent: e.linkComponent,
            context: e.context
          }, null, 8, ["block", "registeredComponents", "linkComponent", "context"]))), 128))
        ]),
        _: 1
      }, 8, ["Wrapper", "block", "context"]))
    ], 64))
  ], 64)) : S("", !0);
}
const ft = /* @__PURE__ */ b(lo, [["render", co]]), ii = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: ft
}, Symbol.toStringTag, { value: "Module" })), uo = g({
  name: "builder-blocks-wrapper",
  props: [
    "blocks",
    "classNameProp",
    "path",
    "parent",
    "styleProp",
    "BlocksWrapperProps",
    "BlocksWrapper"
  ],
  data() {
    return { shouldUpdate: !1 };
  },
  mounted() {
  },
  watch: {
    onUpdateHook0: { handler() {
    }, immediate: !0 }
  },
  computed: {
    className() {
      var e;
      return [
        "builder-blocks",
        (e = this.blocks) != null && e.length ? "" : "no-blocks",
        this.classNameProp
      ].filter(Boolean).join(" ");
    },
    dataPath() {
      if (!this.path)
        return;
      const e = "this.", t = "component.options.";
      return this.path.startsWith(e) ? this.path.replace(e, "") : this.path.startsWith(t) ? this.path : `${t}${this.path || ""}`;
    },
    onUpdateHook0() {
      return {
        0: this.blocks
      };
    }
  },
  methods: {
    onClick() {
      var e, t;
      E() && !((e = this.blocks) != null && e.length) && ((t = window.parent) == null || t.postMessage(
        {
          type: "builder.clickEmptyBlocks",
          data: {
            parentElementId: this.parent,
            dataPath: this.dataPath
          }
        },
        "*"
      ));
    },
    onMouseEnter() {
      var e, t;
      E() && !((e = this.blocks) != null && e.length) && ((t = window.parent) == null || t.postMessage(
        {
          type: "builder.hoverEmptyBlocks",
          data: {
            parentElementId: this.parent,
            dataPath: this.dataPath
          }
        },
        "*"
      ));
    }
  }
});
function po(e, t, n, o, r, s) {
  return c(), y(z(e.BlocksWrapper), C({
    ref: "blocksWrapperRef",
    class: e.className + " component-tqyw9m0iue",
    "builder-path": e.dataPath,
    "builder-parent-id": e.parent,
    style: e.styleProp,
    onClick: (i) => e.onClick(),
    onMouseEnter: (i) => e.onMouseEnter(),
    onKeyPress: (i) => e.onClick()
  }, { ...e.BlocksWrapperProps }), {
    default: W(() => [
      A(e.$slots, "default", {}, void 0, !0)
    ]),
    _: 3
  }, 16, ["class", "builder-path", "builder-parent-id", "style", "onClick", "onMouseEnter", "onKeyPress"]);
}
const mo = /* @__PURE__ */ b(uo, [["render", po], ["__scopeId", "data-v-e05fb921"]]), ho = g({
  name: "builder-blocks",
  components: { BlocksWrapper: mo, Block: ft },
  props: [
    "blocks",
    "parent",
    "path",
    "styleProp",
    "className",
    "context",
    "linkComponent",
    "registeredComponents"
  ],
  inject: {
    builderContext: de.key,
    componentsContext: De.key
  }
});
function fo(e, t, n, o, r, s) {
  var a, u, h, m;
  const i = k("Block"), l = k("BlocksWrapper");
  return c(), y(l, {
    blocks: e.blocks,
    parent: e.parent,
    path: e.path,
    styleProp: e.styleProp,
    classNameProp: e.className,
    BlocksWrapper: ((a = e.context) == null ? void 0 : a.BlocksWrapper) || ((u = e.builderContext) == null ? void 0 : u.BlocksWrapper),
    BlocksWrapperProps: ((h = e.context) == null ? void 0 : h.BlocksWrapperProps) || ((m = e.builderContext) == null ? void 0 : m.BlocksWrapperProps)
  }, {
    default: W(() => [
      e.blocks ? (c(!0), p($, { key: 0 }, N(e.blocks, (d, f) => {
        var v;
        return c(), y(i, {
          key: d.id,
          block: d,
          linkComponent: e.linkComponent,
          context: e.context || e.builderContext,
          registeredComponents: e.registeredComponents || ((v = e.componentsContext) == null ? void 0 : v.registeredComponents)
        }, null, 8, ["block", "linkComponent", "context", "registeredComponents"]);
      }), 128)) : S("", !0)
    ]),
    _: 1
  }, 8, ["blocks", "parent", "path", "styleProp", "classNameProp", "BlocksWrapper", "BlocksWrapperProps"]);
}
const H = /* @__PURE__ */ b(ho, [["render", fo]]), go = g({
  name: "dynamic-div",
  props: []
});
function bo(e, t, n, o, r, s) {
  return c(), p("div", null, [
    A(e.$slots, "default")
  ]);
}
const X = /* @__PURE__ */ b(go, [["render", bo]]), yo = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: X
}, Symbol.toStringTag, { value: "Module" })), Ce = g({
  render() {
    return Ue("style", {
      innerHTML: this.styles,
      "data-id": this.id,
      nonce: this.nonce
    });
  },
  name: "inlined-styles",
  props: ["styles", "id", "nonce"]
}), gt = {
  xsmall: {
    min: 0,
    default: 160,
    max: 320
  },
  small: {
    min: 321,
    default: 321,
    max: 640
  },
  medium: {
    min: 641,
    default: 642,
    max: 991
  },
  large: {
    min: 990,
    default: 991,
    max: 1200
  }
}, ai = (e, t = gt) => `@media (max-width: ${t[e].max}px)`, ko = (e) => {
  const t = fe(gt);
  if (!e)
    return t;
  const {
    xsmall: n,
    small: o,
    medium: r
  } = e;
  if (n) {
    const a = Math.floor(n / 2);
    t.xsmall = {
      max: n,
      min: a,
      default: a + 1
    };
  }
  if (!o || !r)
    return t;
  const s = n ? t.xsmall.max + 1 : Math.floor(o / 2);
  t.small = {
    max: o,
    min: s,
    default: s + 1
  };
  const i = t.small.max + 1;
  t.medium = {
    max: r,
    min: i,
    default: i + 1
  };
  const l = t.medium.max + 1;
  return t.large = {
    max: 2e3,
    // TODO: decide upper limit
    min: l,
    default: l + 1
  }, t;
}, vo = (e) => `builder-columns ${e}-breakpoints`, So = g({
  name: "builder-columns",
  components: {
    InlinedStyles: Ce,
    DynamicRenderer: he,
    Blocks: H,
    DynamicDiv: X
  },
  props: [
    "space",
    "columns",
    "stackColumnsAt",
    "builderLinkComponent",
    "reverseColumnsWhenStacked",
    "builderContext",
    "builderBlock",
    "builderComponents"
  ],
  data() {
    return { TARGET: R, getColumnsClass: vo };
  },
  computed: {
    gutterSize() {
      return typeof this.space == "number" ? this.space || 0 : 20;
    },
    cols() {
      return this.columns || [];
    },
    stackAt() {
      return this.stackColumnsAt || "tablet";
    },
    flexDir() {
      return this.stackColumnsAt === "never" ? "row" : this.reverseColumnsWhenStacked ? "column-reverse" : "column";
    }
  },
  methods: {
    getTagName(e) {
      return e.link ? this.builderLinkComponent || "a" : "div";
    },
    getWidth(e) {
      var t;
      return ((t = this.cols[e]) == null ? void 0 : t.width) || 100 / this.cols.length;
    },
    getColumnCssWidth(e) {
      const t = this.getWidth(e), n = this.gutterSize * (this.cols.length - 1) * (t / 100);
      return `calc(${t}% - ${n}px)`;
    },
    getTabletStyle({
      stackedStyle: e,
      desktopStyle: t
    }) {
      return this.stackAt === "tablet" ? e : t;
    },
    getMobileStyle({
      stackedStyle: e,
      desktopStyle: t
    }) {
      return this.stackAt === "never" ? t : e;
    },
    columnsCssVars() {
      return {
        "--flex-dir": this.flexDir,
        "--flex-dir-tablet": this.getTabletStyle({
          stackedStyle: this.flexDir,
          desktopStyle: "row"
        })
      };
    },
    columnCssVars(e) {
      const t = e === 0 ? 0 : this.gutterSize, n = this.getColumnCssWidth(e), o = `${t}px`, r = "100%", s = 0;
      return {
        ...{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch"
        },
        width: n,
        ["margin-left"]: o,
        "--column-width-mobile": this.getMobileStyle({
          stackedStyle: r,
          desktopStyle: n
        }),
        "--column-margin-left-mobile": this.getMobileStyle({
          stackedStyle: s,
          desktopStyle: o
        }),
        "--column-width-tablet": this.getTabletStyle({
          stackedStyle: r,
          desktopStyle: n
        }),
        "--column-margin-left-tablet": this.getTabletStyle({
          stackedStyle: s,
          desktopStyle: o
        })
      };
    },
    getWidthForBreakpointSize(e) {
      var n, o;
      return ko(
        ((o = (n = this.builderContext.content) == null ? void 0 : n.meta) == null ? void 0 : o.breakpoints) || {}
      )[e].max;
    },
    columnsStyles() {
      const e = `.${this.builderBlock.id}-breakpoints > .builder-column`;
      return `
        @media (max-width: ${this.getWidthForBreakpointSize("medium")}px) {
          .${this.builderBlock.id}-breakpoints {
            flex-direction: var(--flex-dir-tablet);
            align-items: stretch;
          }

          ${e} {
            width: var(--column-width-tablet) !important;
            margin-left: var(--column-margin-left-tablet) !important;
          }
        }

        @media (max-width: ${this.getWidthForBreakpointSize("small")}px) {
          .${this.builderBlock.id}-breakpoints {
            flex-direction: var(--flex-dir);
            align-items: stretch;
          }

          ${e} {
            width: var(--column-width-mobile) !important;
            margin-left: var(--column-margin-left-mobile) !important;
          }
        },
      `;
    },
    getAttributes(e, t) {
      return {
        ...e.link ? {
          href: e.link
        } : {},
        [_()]: "builder-column",
        style: mt(this.columnCssVars(t))
      };
    }
  }
});
function Co(e, t, n, o, r, s) {
  var u;
  const i = k("InlinedStyles"), l = k("Blocks"), a = k("DynamicRenderer");
  return c(), p("div", C({
    class: e.getColumnsClass((u = e.builderBlock) == null ? void 0 : u.id) + " div-4wbls88y960",
    style: e.columnsCssVars()
  }, {}), [
    e.TARGET !== "reactNative" ? (c(), y(i, {
      key: 0,
      id: "builderio-columns",
      styles: e.columnsStyles(),
      nonce: e.builderContext.nonce
    }, null, 8, ["styles", "nonce"])) : S("", !0),
    (c(!0), p($, null, N(e.columns, (h, m) => (c(), y(a, {
      key: m,
      TagName: e.getTagName(h),
      actionAttributes: {},
      attributes: e.getAttributes(h, m)
    }, {
      default: W(() => [
        B(l, {
          path: `columns.${m}.blocks`,
          parent: e.builderBlock.id,
          context: e.builderContext,
          registeredComponents: e.builderComponents,
          linkComponent: e.builderLinkComponent,
          blocks: h.blocks,
          styleProp: {
            flexGrow: "1"
          }
        }, null, 8, ["path", "parent", "context", "registeredComponents", "linkComponent", "blocks"])
      ]),
      _: 2
    }, 1032, ["TagName", "attributes"]))), 128))
  ], 16);
}
const wo = /* @__PURE__ */ b(So, [["render", Co], ["__scopeId", "data-v-bdc9da04"]]), To = g({
  name: "builder-fragment-component",
  props: []
});
function Io(e, t, n, o, r, s) {
  return c(), p("span", null, [
    A(e.$slots, "default")
  ]);
}
const $o = /* @__PURE__ */ b(To, [["render", Io]]);
function Ve(e) {
  return e.replace(/http(s)?:/, "");
}
function Ro(e = "", t, n) {
  const o = new RegExp("([?&])" + t + "=.*?(&|$)", "i"), r = e.indexOf("?") !== -1 ? "&" : "?";
  return e.match(o) ? e.replace(o, "$1" + t + "=" + encodeURIComponent(n) + "$2") : e + r + t + "=" + encodeURIComponent(n);
}
function Eo(e, t) {
  if (!e || !(e != null && e.match(/cdn\.shopify\.com/)) || !t)
    return e;
  if (t === "master")
    return Ve(e);
  const n = e.match(/(_\d+x(\d+)?)?(\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?)/i);
  if (n) {
    const o = e.split(n[0]), r = n[3], s = t.match("x") ? t : `${t}x`;
    return Ve(`${o[0]}_${s}${r}`);
  }
  return null;
}
function se(e) {
  if (!e)
    return e;
  const t = [100, 200, 400, 800, 1200, 1600, 2e3];
  if (e.match(/builder\.io/)) {
    let n = e;
    const o = Number(e.split("?width=")[1]);
    return isNaN(o) || (n = `${n} ${o}w`), t.filter((r) => r !== o).map((r) => `${Ro(e, "width", r)} ${r}w`).concat([n]).join(", ");
  }
  return e.match(/cdn\.shopify\.com/) ? t.map((n) => [Eo(e, `${n}x${n}`), n]).filter(([n]) => !!n).map(([n, o]) => `${n} ${o}w`).concat([e]).join(", ") : e;
}
const Po = g({
  name: "builder-image",
  props: [
    "image",
    "src",
    "srcset",
    "noWebp",
    "aspectRatio",
    "highPriority",
    "altText",
    "title",
    "backgroundPosition",
    "backgroundSize",
    "className",
    "sizes",
    "builderBlock",
    "fitContent"
  ],
  computed: {
    srcSetToUse() {
      var n;
      const t = this.image || this.src;
      if (!t || // We can auto add srcset for cdn.builder.io and shopify
      // images, otherwise you can supply this prop manually
      !(typeof t == "string" && (t.match(/builder\.io/) || t.match(/cdn\.shopify\.com/))))
        return this.srcset;
      if (!this.noWebp) {
        if (this.srcset && ((n = this.image) != null && n.includes("builder.io/api/v1/image"))) {
          if (!this.srcset.includes(this.image.split("?")[0]))
            return console.debug("Removed given srcset"), se(t);
        } else if (this.image && !this.srcset)
          return se(t);
        return se(t);
      }
    },
    webpSrcSet() {
      var e;
      return (e = this.srcSetToUse) != null && e.match(/builder\.io/) && !this.noWebp ? this.srcSetToUse.replace(/\?/g, "?format=webp&") : "";
    },
    aspectRatioCss() {
      const e = {
        position: "absolute",
        height: "100%",
        width: "100%",
        left: "0px",
        top: "0px"
      };
      return this.aspectRatio ? e : void 0;
    }
  }
}), Bo = ["srcset"], Ao = ["loading", "fetchpriority", "alt", "title", "role", "src", "srcset", "sizes"], xo = {
  key: 2,
  class: "div-29wpyomtntv-2"
};
function Vo(e, t, n, o, r, s) {
  var i, l, a, u, h, m;
  return c(), p($, null, [
    M("picture", null, [
      e.webpSrcSet ? (c(), p("source", {
        key: 0,
        type: "image/webp",
        srcset: e.webpSrcSet
      }, null, 8, Bo)) : S("", !0),
      M("img", {
        loading: e.highPriority ? "eager" : "lazy",
        fetchpriority: e.highPriority ? "high" : "auto",
        alt: e.altText,
        title: e.title,
        role: e.altText ? void 0 : "presentation",
        style: U({
          objectPosition: e.backgroundPosition || "center",
          objectFit: e.backgroundSize || "cover",
          ...e.aspectRatioCss
        }),
        class: J(
          "builder-image" + (e.className ? " " + e.className : "") + " img-29wpyomtntv"
        ),
        src: e.image,
        srcset: e.srcSetToUse,
        sizes: e.sizes
      }, null, 14, Ao)
    ]),
    e.aspectRatio && !((l = (i = e.builderBlock) == null ? void 0 : i.children) != null && l.length && e.fitContent) ? (c(), p("div", {
      key: 0,
      class: "builder-image-sizer div-29wpyomtntv",
      style: U({
        paddingTop: e.aspectRatio * 100 + "%"
      })
    }, null, 4)) : S("", !0),
    (u = (a = e.builderBlock) == null ? void 0 : a.children) != null && u.length && e.fitContent ? A(e.$slots, "default", { key: 1 }, void 0, !0) : S("", !0),
    !e.fitContent && ((m = (h = e.builderBlock) == null ? void 0 : h.children) != null && m.length) ? (c(), p("div", xo, [
      A(e.$slots, "default", {}, void 0, !0)
    ])) : S("", !0)
  ], 64);
}
const No = /* @__PURE__ */ b(Po, [["render", Vo], ["__scopeId", "data-v-717bf141"]]), Wo = g({
  name: "builder-section-component",
  props: ["attributes", "maxWidth"],
  data() {
    return { filterAttrs: V };
  }
});
function Oo(e, t, n, o, r, s) {
  return c(), p("section", C({
    style: {
      width: "100%",
      alignSelf: "stretch",
      flexGrow: 1,
      boxSizing: "border-box",
      maxWidth: e.maxWidth || 1200,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), [
    A(e.$slots, "default")
  ], 16);
}
const Uo = /* @__PURE__ */ b(Wo, [["render", Oo]]), ie = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Accordion item title",
  responsiveStyles: {
    large: {
      marginTop: "10px",
      position: "relative",
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
      paddingBottom: "10px"
    }
  },
  children: [{
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        textAlign: "left",
        display: "flex",
        flexDirection: "column"
      }
    },
    component: {
      name: "Text",
      options: {
        text: "I am an accordion title. Click me!"
      }
    }
  }]
}, ae = {
  "@type": "@builder.io/sdk:Element",
  layerName: "Accordion item detail",
  responsiveStyles: {
    large: {
      position: "relative",
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
      marginTop: "10px",
      paddingBottom: "10px"
    }
  },
  children: [{
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        paddingTop: "50px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "50px"
      }
    },
    component: {
      name: "Text",
      options: {
        text: "I am an accordion detail, hello!"
      }
    }
  }]
}, Do = {
  name: "Builder:Accordion",
  canHaveChildren: !0,
  image: "https://cdn.builder.io/api/v1/image/assets%2FagZ9n5CUKRfbL9t6CaJOyVSK4Es2%2Ffab6c1fd3fe542408cbdec078bca7f35",
  defaultStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },
  inputs: [{
    name: "items",
    type: "list",
    broadcast: !0,
    subFields: [{
      name: "title",
      type: "uiBlocks",
      hideFromUI: !0,
      defaultValue: [ie]
    }, {
      name: "detail",
      type: "uiBlocks",
      hideFromUI: !0,
      defaultValue: [ae]
    }],
    defaultValue: [{
      title: [ie],
      detail: [ae]
    }, {
      title: [ie],
      detail: [ae]
    }],
    showIf: (e) => !e.get("useChildrenForItems")
  }, {
    name: "oneAtATime",
    helperText: "Only allow opening one at a time (collapse all others when new item openned)",
    type: "boolean",
    defaultValue: !1
  }, {
    name: "grid",
    helperText: "Display as a grid",
    type: "boolean",
    defaultValue: !1
  }, {
    name: "gridRowWidth",
    helperText: "Display as a grid",
    type: "string",
    showIf: (e) => e.get("grid"),
    defaultValue: "25%"
  }, {
    name: "useChildrenForItems",
    type: "boolean",
    helperText: "Use child elements for each slide, instead of the array. Useful for dynamically repeating items",
    advanced: !0,
    defaultValue: !1,
    onChange: (e) => {
      e.get("useChildrenForItems") === !0 && e.set("items", []);
    }
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0,
    builderContext: !0,
    builderComponents: !0,
    builderLinkComponent: !0
  }
}, Fo = (e) => e.toString(), Lo = g({
  name: "builder-accordion",
  components: { Blocks: H },
  props: [
    "grid",
    "oneAtATime",
    "items",
    "gridRowWidth",
    "builderBlock",
    "builderContext",
    "builderComponents",
    "builderLinkComponent"
  ],
  data() {
    return { open: [], convertOrderNumberToString: Fo };
  },
  computed: {
    onlyOneAtATime() {
      return !!(this.grid || this.oneAtATime);
    },
    accordionStyles() {
      return {
        display: "flex",
        alignItems: "stretch",
        flexDirection: "column",
        ...this.grid && {
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap"
        }
      };
    },
    accordionTitleStyles() {
      const t = {
        ...{
          display: "flex",
          flexDirection: "column"
        },
        alignItems: "stretch",
        cursor: "pointer"
      };
      return Object.fromEntries(
        Object.entries(t).filter(([n, o]) => o !== void 0)
      );
    },
    openGridItemOrder() {
      let e = null;
      if (this.grid && this.open.length && document) {
        const n = this.open[0];
        let r = document.querySelector(
          `.builder-accordion-title[data-index="${n}"]`
        );
        if (e = n, r) {
          let s = r.getBoundingClientRect();
          for (; r = r && r.nextElementSibling; )
            if (r) {
              if (r.classList.contains("builder-accordion-detail"))
                continue;
              const i = r.getBoundingClientRect();
              if (i.left > s.left) {
                const l = parseInt(
                  r.getAttribute("data-index") || "",
                  10
                );
                isNaN(l) || (s = i, e = l);
              } else
                break;
            }
        }
      }
      return typeof e == "number" && (e = e + 1), e;
    },
    accordionDetailStyles() {
      const e = {
        order: typeof this.openGridItemOrder == "number" ? this.openGridItemOrder : void 0,
        ...this.grid && {
          width: "100%"
        }
      };
      return Object.fromEntries(
        Object.entries(e).filter(([t, n]) => n !== void 0)
      );
    }
  },
  methods: {
    getAccordionTitleClassName(e) {
      return `builder-accordion-title builder-accordion-title-${this.open.includes(e) ? "open" : "closed"}`;
    },
    getAccordionDetailClassName(e) {
      return `builder-accordion-detail builder-accordion-detail-${this.open.includes(e) ? "open" : "closed"}`;
    },
    onClick(e) {
      this.open.includes(e) ? this.open = this.onlyOneAtATime ? [] : this.open.filter((t) => t !== e) : this.open = this.onlyOneAtATime ? [e] : this.open.concat(e);
    }
  }
}), Mo = ["data-index", "onClick"];
function Ho(e, t, n, o, r, s) {
  const i = k("Blocks");
  return c(), p("div", {
    class: "builder-accordion",
    style: U(e.accordionStyles)
  }, [
    (c(!0), p($, null, N(e.items, (l, a) => (c(), p($, { key: a }, [
      M("div", {
        class: J(e.getAccordionTitleClassName(a)),
        style: U({
          ...e.accordionTitleStyles,
          ...e.grid && {
            width: e.gridRowWidth,
            order: e.openGridItemOrder !== null ? e.convertOrderNumberToString(a) : e.convertOrderNumberToString(a + 1)
          }
        }),
        "data-index": a,
        onClick: async (u) => e.onClick(a)
      }, [
        B(i, {
          blocks: l.title,
          path: `items.${a}.title`,
          parent: e.builderBlock.id,
          context: e.builderContext,
          registeredComponents: e.builderComponents,
          linkComponent: e.builderLinkComponent
        }, null, 8, ["blocks", "path", "parent", "context", "registeredComponents", "linkComponent"])
      ], 14, Mo),
      e.open.includes(a) ? (c(), p("div", {
        key: 0,
        class: J(e.getAccordionDetailClassName(a)),
        style: U(e.accordionDetailStyles)
      }, [
        B(i, {
          blocks: l.detail,
          path: `items.${a}.detail`,
          parent: e.builderBlock.id,
          context: e.builderContext,
          registeredComponents: e.builderComponents,
          linkComponent: e.builderLinkComponent
        }, null, 8, ["blocks", "path", "parent", "context", "registeredComponents", "linkComponent"])
      ], 6)) : S("", !0)
    ], 64))), 128))
  ], 4);
}
const jo = /* @__PURE__ */ b(Lo, [["render", Ho]]), Ko = {
  name: "Core:Button",
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13",
  defaultStyles: {
    // TODO: make min width more intuitive and set one
    appearance: "none",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: "#000000",
    color: "white",
    borderRadius: "4px",
    textAlign: "center",
    cursor: "pointer"
  },
  inputs: [{
    name: "text",
    type: "text",
    defaultValue: "Click me!",
    bubble: !0
  }, {
    name: "link",
    type: "url",
    bubble: !0
  }, {
    name: "openLinkInNewTab",
    type: "boolean",
    defaultValue: !1,
    friendlyName: "Open link in new tab"
  }],
  static: !0,
  noWrap: !0,
  shouldReceiveBuilderProps: {
    builderLinkComponent: !0
  }
}, zo = {
  // TODO: ways to statically preprocess JSON for references, functions, etc
  name: "Columns",
  isRSC: !0,
  inputs: [{
    name: "columns",
    type: "array",
    broadcast: !0,
    subFields: [{
      name: "blocks",
      type: "array",
      hideFromUI: !0,
      defaultValue: [{
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto",
            minHeight: "20px",
            minWidth: "20px",
            overflow: "hidden"
          }
        },
        component: {
          name: "Image",
          options: {
            image: "https://builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: 0.7004048582995948
          }
        }
      }, {
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto"
          }
        },
        component: {
          name: "Text",
          options: {
            text: "<p>Enter some text...</p>"
          }
        }
      }]
    }, {
      name: "width",
      type: "number",
      hideFromUI: !0,
      helperText: "Width %, e.g. set to 50 to fill half of the space"
    }, {
      name: "link",
      type: "url",
      helperText: "Optionally set a url that clicking this column will link to"
    }],
    defaultValue: [{
      blocks: [{
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto",
            minHeight: "20px",
            minWidth: "20px",
            overflow: "hidden"
          }
        },
        component: {
          name: "Image",
          options: {
            image: "https://builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: 0.7004048582995948
          }
        }
      }, {
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto"
          }
        },
        component: {
          name: "Text",
          options: {
            text: "<p>Enter some text...</p>"
          }
        }
      }]
    }, {
      blocks: [{
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto",
            minHeight: "20px",
            minWidth: "20px",
            overflow: "hidden"
          }
        },
        component: {
          name: "Image",
          options: {
            image: "https://builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d",
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: 0.7004048582995948
          }
        }
      }, {
        "@type": "@builder.io/sdk:Element",
        responsiveStyles: {
          large: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            flexShrink: "0",
            position: "relative",
            marginTop: "30px",
            textAlign: "center",
            lineHeight: "normal",
            height: "auto"
          }
        },
        component: {
          name: "Text",
          options: {
            text: "<p>Enter some text...</p>"
          }
        }
      }]
    }],
    onChange: (e) => {
      function t() {
        n.forEach((o) => {
          o.delete("width");
        });
      }
      const n = e.get("columns");
      Array.isArray(n) && n.find((r) => r.get("width")) && (n.find((s) => !s.get("width")) || n.reduce((l, a) => l + a.get("width"), 0) !== 100) && t();
    }
  }, {
    name: "space",
    type: "number",
    defaultValue: 20,
    helperText: "Size of gap between columns",
    advanced: !0
  }, {
    name: "stackColumnsAt",
    type: "string",
    defaultValue: "tablet",
    helperText: "Convert horizontal columns to vertical at what device size",
    enum: ["tablet", "mobile", "never"],
    advanced: !0
  }, {
    name: "reverseColumnsWhenStacked",
    type: "boolean",
    defaultValue: !1,
    helperText: "When stacking columns for mobile devices, reverse the ordering",
    advanced: !0
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0,
    builderContext: !0,
    builderComponents: !0,
    builderLinkComponent: !0
  }
}, qo = {
  name: "Fragment",
  static: !0,
  hidden: !0,
  canHaveChildren: !0,
  noWrap: !0
}, we = ["jpeg", "jpg", "png", "svg", "webp", "gif", "jfif", "pjpeg", "pjp", "apng", "avif", "tif", "tiff", "heif", "bmp", "eps", "raw", "cr2", "nef", "orf", "sr2", "psd", "heic", "dib", "ai"], Jo = ["mp4", "webm", "mkv", "flv", "vob", "ogv", "ogg", "drc", "gif", "gifv", "mng", "avi", "mov", "qt", "mts", "m2ts", "ts", "wmv", "yuv", "rm", "rmvb", "viv", "asf", "amv", "m4p", "mpeg", "mpe", "m2v", "m4v", "svi", "3gp", "3g2", "mxf", "roq", "nsv", "f4v", "f4p", "f4a", "f4b"], Go = {
  name: "Image",
  static: !0,
  image: "https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-insert_photo-24px.svg?alt=media&token=4e5d0ef4-f5e8-4e57-b3a9-38d63a9b9dc4",
  defaultStyles: {
    position: "relative",
    minHeight: "20px",
    minWidth: "20px",
    overflow: "hidden"
  },
  canHaveChildren: !0,
  inputs: [{
    name: "image",
    type: "file",
    bubble: !0,
    allowedFileTypes: we,
    required: !0,
    defaultValue: "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F72c80f114dc149019051b6852a9e3b7a",
    onChange: (e) => {
      e.delete("srcset"), e.delete("noWebp");
      function n(i, l = 6e4) {
        return new Promise((a, u) => {
          const h = document.createElement("img");
          let m = !1;
          h.onload = () => {
            m = !0, a(h);
          }, h.addEventListener("error", (d) => {
            console.warn("Image load failed", d.error), u(d.error);
          }), h.src = i, setTimeout(() => {
            m || u(new Error("Image load timed out"));
          }, l);
        });
      }
      function o(i) {
        return Math.round(i * 1e3) / 1e3;
      }
      const r = e.get("image"), s = e.get("aspectRatio");
      if (fetch(r).then((i) => i.blob()).then((i) => {
        i.type.includes("svg") && e.set("noWebp", !0);
      }), r && (!s || s === 0.7041))
        return n(r).then((i) => {
          const l = e.get("aspectRatio");
          e.get("image") === r && (!l || l === 0.7041) && i.width && i.height && (e.set("aspectRatio", o(i.height / i.width)), e.set("height", i.height), e.set("width", i.width));
        });
    }
  }, {
    name: "backgroundSize",
    type: "text",
    defaultValue: "cover",
    enum: [{
      label: "contain",
      value: "contain",
      helperText: "The image should never get cropped"
    }, {
      label: "cover",
      value: "cover",
      helperText: "The image should fill it's box, cropping when needed"
    }]
  }, {
    name: "backgroundPosition",
    type: "text",
    defaultValue: "center",
    enum: ["center", "top", "left", "right", "bottom", "top left", "top right", "bottom left", "bottom right"]
  }, {
    name: "altText",
    type: "string",
    helperText: "Text to display when the user has images off"
  }, {
    name: "title",
    type: "string",
    helperText: "Text to display when hovering over the asset"
  }, {
    name: "height",
    type: "number",
    hideFromUI: !0
  }, {
    name: "width",
    type: "number",
    hideFromUI: !0
  }, {
    name: "sizes",
    type: "string",
    hideFromUI: !0
  }, {
    name: "srcset",
    type: "string",
    hideFromUI: !0
  }, {
    name: "lazy",
    type: "boolean",
    defaultValue: !0,
    hideFromUI: !0
  }, {
    name: "fitContent",
    type: "boolean",
    helperText: "When child blocks are provided, fit to them instead of using the image's aspect ratio",
    defaultValue: !0
  }, {
    name: "highPriority",
    type: "boolean",
    advanced: !0,
    helperText: "Mark this image as high priority compared to other images on the page. This prevents lazy loading of the image and tells the browser to load this image before others on the page."
  }, {
    name: "aspectRatio",
    type: "number",
    helperText: "This is the ratio of height/width, e.g. set to 1.5 for a 300px wide and 200px tall photo. Set to 0 to not force the image to maintain it's aspect ratio",
    advanced: !0,
    defaultValue: 0.7041
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0
  }
}, bt = g({
  render() {
    return Ue("script", {
      innerHTML: this.scriptStr,
      "data-id": this.id,
      nonce: this.nonce
    });
  },
  name: "inlined-script",
  props: ["scriptStr", "id", "nonce"]
}), Yo = {
  name: "Core:Section",
  static: !0,
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F682efef23ace49afac61748dd305c70a",
  inputs: [{
    name: "maxWidth",
    type: "number",
    defaultValue: 1200
  }, {
    name: "lazyLoad",
    type: "boolean",
    defaultValue: !1,
    advanced: !0,
    description: "Only render this section when in view"
  }],
  defaultStyles: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "50px",
    paddingBottom: "50px",
    marginTop: "0px",
    width: "100vw",
    marginLeft: "calc(50% - 50vw)"
  },
  canHaveChildren: !0,
  defaultChildren: [{
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        textAlign: "center"
      }
    },
    component: {
      name: "Text",
      options: {
        text: "<p><b>I am a section! My content keeps from getting too wide, so that it's easy to read even on big screens.</b></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>"
      }
    }
  }]
}, Xo = {
  name: "Slot",
  isRSC: !0,
  description: "Allow child blocks to be inserted into this content when used as a Symbol",
  docsLink: "https://www.builder.io/c/docs/symbols-with-blocks",
  image: "https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F3aad6de36eae43b59b52c85190fdef56",
  // Maybe wrap this for canHaveChildren so bind children to this hm
  inputs: [{
    name: "name",
    type: "string",
    required: !0,
    defaultValue: "children"
  }],
  shouldReceiveBuilderProps: {
    builderContext: !0,
    builderComponents: !0
  }
}, Qo = g({
  name: "builder-slot",
  components: { Blocks: H },
  props: ["builderContext", "name", "builderComponents"]
});
function Zo(e, t, n, o, r, s) {
  var l, a, u;
  const i = k("Blocks");
  return c(), p("div", C(
    { style: {
      pointerEvents: "auto"
    } },
    !((l = e.builderContext.context) != null && l.symbolId) && {
      "builder-slot": e.name
    }
  ), [
    B(i, {
      parent: (a = e.builderContext.context) == null ? void 0 : a.symbolId,
      path: `symbol.data.${e.name}`,
      context: e.builderContext,
      registeredComponents: e.builderComponents,
      blocks: (u = e.builderContext.rootState) == null ? void 0 : u[e.name]
    }, null, 8, ["parent", "path", "context", "registeredComponents", "blocks"])
  ], 16);
}
const _o = /* @__PURE__ */ b(Qo, [["render", Zo]]), er = {
  name: "Symbol",
  noWrap: !0,
  static: !0,
  isRSC: !0,
  inputs: [{
    name: "symbol",
    type: "uiSymbol"
  }, {
    name: "dataOnly",
    helperText: "Make this a data symbol that doesn't display any UI",
    type: "boolean",
    defaultValue: !1,
    advanced: !0,
    hideFromUI: !0
  }, {
    name: "inheritState",
    helperText: "Inherit the parent component state and data",
    type: "boolean",
    defaultValue: !1,
    advanced: !0
  }, {
    name: "renderToLiquid",
    helperText: "Render this symbols contents to liquid. Turn off to fetch with javascript and use custom targeting",
    type: "boolean",
    defaultValue: !1,
    advanced: !0,
    hideFromUI: !0
  }, {
    name: "useChildren",
    hideFromUI: !0,
    type: "boolean"
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0,
    builderContext: !0,
    builderComponents: !0,
    builderLinkComponent: !0
  }
}, le = {
  "@type": "@builder.io/sdk:Element",
  responsiveStyles: {
    large: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      minWidth: "100px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      userSelect: "none"
    }
  },
  component: {
    name: "Text",
    options: {
      text: "New tab"
    }
  }
}, ce = {
  "@type": "@builder.io/sdk:Element",
  responsiveStyles: {
    large: {
      height: "200px",
      display: "flex",
      marginTop: "20px",
      flexDirection: "column"
    }
  },
  component: {
    name: "Text",
    options: {
      text: "New tab content "
    }
  }
}, tr = {
  name: "Builder: Tabs",
  inputs: [{
    name: "tabs",
    type: "list",
    broadcast: !0,
    subFields: [{
      name: "label",
      type: "uiBlocks",
      hideFromUI: !0,
      defaultValue: [le]
    }, {
      name: "content",
      type: "uiBlocks",
      hideFromUI: !0,
      defaultValue: [ce]
    }],
    defaultValue: [{
      label: [{
        ...le,
        component: {
          name: "Text",
          options: {
            text: "Tab 1"
          }
        }
      }],
      content: [{
        ...ce,
        component: {
          name: "Text",
          options: {
            text: "Tab 1 content"
          }
        }
      }]
    }, {
      label: [{
        ...le,
        component: {
          name: "Text",
          options: {
            text: "Tab 2"
          }
        }
      }],
      content: [{
        ...ce,
        component: {
          name: "Text",
          options: {
            text: "Tab 2 content"
          }
        }
      }]
    }]
  }, {
    name: "activeTabStyle",
    type: "uiStyle",
    helperText: "CSS styles for the active tab",
    defaultValue: {
      backgroundColor: "rgba(0, 0, 0, 0.1)"
    }
  }, {
    name: "defaultActiveTab",
    type: "number",
    helperText: 'Default tab to open to. Set to "1" for the first tab, "2" for the second, or choose "0" for none',
    defaultValue: 1,
    advanced: !0
  }, {
    name: "collapsible",
    type: "boolean",
    helperText: "If on, clicking an open tab closes it so no tabs are active",
    defaultValue: !1,
    advanced: !0
  }, {
    name: "tabHeaderLayout",
    type: "enum",
    helperText: "Change the layout of the tab headers (uses justify-content)",
    defaultValue: "flex-start",
    enum: [{
      label: "Center",
      value: "center"
    }, {
      label: "Space between",
      value: "space-between"
    }, {
      label: "Space around",
      value: "space-around"
    }, {
      label: "Left",
      value: "flex-start"
    }, {
      label: "Right",
      value: "flex-end"
    }]
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0,
    builderContext: !0,
    builderComponents: !0,
    builderLinkComponent: !0
  }
}, nr = g({
  name: "builder-tabs",
  components: { Blocks: H },
  props: [
    "defaultActiveTab",
    "tabs",
    "collapsible",
    "tabHeaderLayout",
    "activeTabStyle",
    "builderBlock",
    "builderContext",
    "builderComponents",
    "builderLinkComponent"
  ],
  data() {
    return { activeTab: this.defaultActiveTab ? this.defaultActiveTab - 1 : 0 };
  },
  methods: {
    activeTabContent(e) {
      return this.tabs && this.tabs[e].content;
    },
    onClick(e) {
      e === this.activeTab && this.collapsible ? this.activeTab = -1 : this.activeTab = e;
    }
  }
}), or = ["onClick"], rr = { key: 0 };
function sr(e, t, n, o, r, s) {
  const i = k("Blocks");
  return c(), p("div", null, [
    M("div", {
      class: "builder-tabs-wrap",
      style: U({
        display: "flex",
        flexDirection: "row",
        justifyContent: e.tabHeaderLayout || "flex-start",
        overflow: "auto"
      })
    }, [
      (c(!0), p($, null, N(e.tabs, (l, a) => (c(), p("span", {
        key: a,
        class: J(`builder-tab-wrap ${e.activeTab === a ? "builder-tab-active" : ""}`),
        style: U({
          ...e.activeTab === a ? e.activeTabStyle : {}
        }),
        onClick: async (u) => e.onClick(a)
      }, [
        B(i, {
          parent: e.builderBlock.id,
          path: `tabs.${a}.label`,
          blocks: l.label,
          context: e.builderContext,
          registeredComponents: e.builderComponents,
          linkComponent: e.builderLinkComponent
        }, null, 8, ["parent", "path", "blocks", "context", "registeredComponents", "linkComponent"])
      ], 14, or))), 128))
    ], 4),
    e.activeTabContent(e.activeTab) ? (c(), p("div", rr, [
      B(i, {
        parent: e.builderBlock.id,
        path: `tabs.${e.activeTab}.content`,
        blocks: e.activeTabContent(e.activeTab),
        context: e.builderContext,
        registeredComponents: e.builderComponents,
        linkComponent: e.builderLinkComponent
      }, null, 8, ["parent", "path", "blocks", "context", "registeredComponents", "linkComponent"])
    ])) : S("", !0)
  ]);
}
const ir = /* @__PURE__ */ b(nr, [["render", sr]]), ar = {
  shouldReceiveBuilderProps: {
    builderBlock: !1,
    builderContext: !0
  },
  name: "Text",
  static: !0,
  isRSC: !0,
  image: "https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-text_fields-24px%20(1).svg?alt=media&token=12177b73-0ee3-42ca-98c6-0dd003de1929",
  inputs: [{
    name: "text",
    type: "html",
    required: !0,
    autoFocus: !0,
    bubble: !0,
    defaultValue: "Enter some text..."
  }],
  defaultStyles: {
    lineHeight: "normal",
    height: "auto",
    textAlign: "center"
  }
}, lr = g({
  name: "builder-text",
  props: ["text"]
}), cr = ["innerHTML"];
function dr(e, t, n, o, r, s) {
  var i;
  return c(), p("div", {
    class: "builder-text",
    innerHTML: ((i = e.text) == null ? void 0 : i.toString()) || "",
    style: {
      outline: "none"
    }
  }, null, 8, cr);
}
const ur = /* @__PURE__ */ b(lr, [["render", dr]]), pr = {
  name: "Custom Code",
  static: !0,
  requiredPermissions: ["editCode"],
  inputs: [{
    name: "code",
    type: "html",
    required: !0,
    defaultValue: "<p>Hello there, I am custom HTML code!</p>",
    code: !0
  }, {
    name: "replaceNodes",
    type: "boolean",
    helperText: "Preserve server rendered dom nodes",
    advanced: !0
  }, {
    name: "scriptsClientOnly",
    type: "boolean",
    defaultValue: !1,
    helperText: "Only print and run scripts on the client. Important when scripts influence DOM that could be replaced when client loads",
    advanced: !0
  }]
}, mr = g({
  name: "builder-custom-code",
  props: ["code", "replaceNodes"],
  data() {
    return { scriptsInserted: [], scriptsRun: [] };
  },
  mounted() {
    this.runScripts();
  },
  watch: {
    onUpdateHook0: {
      handler() {
        E() && setTimeout(() => {
          this.runScripts();
        }, 0);
      },
      immediate: !0
    }
  },
  computed: {
    onUpdateHook0() {
      return {
        0: this.code
      };
    }
  },
  methods: {
    runScripts() {
      var t;
      if (!this.$refs.elementRef || !((t = this.$refs.elementRef) != null && t.getElementsByTagName) || typeof window > "u")
        return;
      const e = this.$refs.elementRef.getElementsByTagName("script");
      for (let n = 0; n < e.length; n++) {
        const o = e[n];
        if (o.src) {
          if (this.scriptsInserted.includes(o.src))
            continue;
          this.scriptsInserted.push(o.src);
          const r = document.createElement("script");
          r.async = !0, r.src = o.src, document.head.appendChild(r);
        } else if (!o.type || [
          "text/javascript",
          "application/javascript",
          "application/ecmascript"
        ].includes(o.type)) {
          if (this.scriptsRun.includes(o.innerText))
            continue;
          try {
            this.scriptsRun.push(o.innerText), new Function(o.innerText)();
          } catch (r) {
            P.warn(
              "[BUILDER.IO] `CustomCode`: Error running script:",
              r
            );
          }
        }
      }
    }
  }
}), hr = ["innerHTML"];
function fr(e, t, n, o, r, s) {
  return c(), p("div", {
    ref: "elementRef",
    class: J("builder-custom-code" + (e.replaceNodes ? " replace-nodes" : "")),
    innerHTML: e.code
  }, null, 10, hr);
}
const gr = /* @__PURE__ */ b(mr, [["render", fr]]), br = {
  name: "Embed",
  static: !0,
  inputs: [{
    name: "url",
    type: "url",
    required: !0,
    defaultValue: "",
    helperText: "e.g. enter a youtube url, google map, etc"
  }, {
    name: "content",
    type: "html",
    defaultValue: '<div style="padding: 20px; text-align: center">(Choose an embed URL)<div>',
    hideFromUI: !0
  }]
}, yr = ["text/javascript", "application/javascript", "application/ecmascript"], kr = (e) => yr.includes(e.type), vr = g({
  name: "builder-embed",
  props: ["content"],
  data() {
    return { scriptsInserted: [], scriptsRun: [], ranInitFn: !1 };
  },
  watch: {
    onUpdateHook0: {
      handler() {
        this.$refs.elem && !this.ranInitFn && (this.ranInitFn = !0, this.findAndRunScripts());
      },
      immediate: !0
    }
  },
  computed: {
    onUpdateHook0() {
      return {
        0: this.$refs.elem,
        1: this.ranInitFn
      };
    }
  },
  methods: {
    findAndRunScripts() {
      if (!this.$refs.elem || !this.$refs.elem.getElementsByTagName)
        return;
      const e = this.$refs.elem.getElementsByTagName("script");
      for (let t = 0; t < e.length; t++) {
        const n = e[t];
        if (n.src && !this.scriptsInserted.includes(n.src)) {
          this.scriptsInserted.push(n.src);
          const o = document.createElement("script");
          o.async = !0, o.src = n.src, document.head.appendChild(o);
        } else if (kr(n) && !this.scriptsRun.includes(n.innerText))
          try {
            this.scriptsRun.push(n.innerText), new Function(n.innerText)();
          } catch (o) {
            P.warn("[BUILDER.IO] `Embed`: Error running script:", o);
          }
      }
    }
  }
}), Sr = ["innerHTML"];
function Cr(e, t, n, o, r, s) {
  return c(), p("div", {
    class: "builder-embed",
    ref: "elem",
    innerHTML: e.content
  }, null, 8, Sr);
}
const wr = /* @__PURE__ */ b(vr, [["render", Cr]]), Tr = {
  name: "Form:Form",
  // editableTags: ['builder-form-error']
  defaults: {
    responsiveStyles: {
      large: {
        marginTop: "15px",
        paddingBottom: "15px"
      }
    }
  },
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fef36d2a846134910b64b88e6d18c5ca5",
  inputs: [{
    name: "sendSubmissionsTo",
    type: "string",
    // TODO: save to builder data and user can download as csv
    // TODO: easy for mode too or computed add/remove fields form mode
    // so you can edit details and high level mode at same time...
    // Later - more integrations like mailchimp
    // /api/v1/form-submit?to=mailchimp
    enum: [{
      label: "Send to email",
      value: "email",
      helperText: "Send form submissions to the email address of your choosing"
    }, {
      label: "Custom",
      value: "custom",
      helperText: "Handle where the form requests go manually with a little code, e.g. to your own custom backend"
    }],
    defaultValue: "email"
  }, {
    name: "sendSubmissionsToEmail",
    type: "string",
    required: !0,
    // TODO: required: () => options.get("sendSubmissionsTo") === "email"
    defaultValue: "your@email.com",
    showIf: 'options.get("sendSubmissionsTo") === "email"'
  }, {
    name: "sendWithJs",
    type: "boolean",
    helperText: "Set to false to use basic html form action",
    defaultValue: !0,
    showIf: 'options.get("sendSubmissionsTo") === "custom"'
  }, {
    name: "name",
    type: "string",
    defaultValue: "My form"
    // advanced: true
  }, {
    name: "action",
    type: "string",
    helperText: "URL to send the form data to",
    showIf: 'options.get("sendSubmissionsTo") === "custom"'
  }, {
    name: "contentType",
    type: "string",
    defaultValue: "application/json",
    advanced: !0,
    // TODO: do automatically if file input
    enum: ["application/json", "multipart/form-data", "application/x-www-form-urlencoded"],
    showIf: 'options.get("sendSubmissionsTo") === "custom" && options.get("sendWithJs") === true'
  }, {
    name: "method",
    type: "string",
    showIf: 'options.get("sendSubmissionsTo") === "custom"',
    defaultValue: "POST",
    advanced: !0
  }, {
    name: "previewState",
    type: "string",
    // TODO: persist: false flag
    enum: ["unsubmitted", "sending", "success", "error"],
    defaultValue: "unsubmitted",
    helperText: 'Choose a state to edit, e.g. choose "success" to show what users see on success and edit the message',
    showIf: 'options.get("sendSubmissionsTo") !== "zapier" && options.get("sendWithJs") === true'
  }, {
    name: "successUrl",
    type: "url",
    helperText: "Optional URL to redirect the user to on form submission success",
    showIf: 'options.get("sendSubmissionsTo") !== "zapier" && options.get("sendWithJs") === true'
  }, {
    name: "resetFormOnSubmit",
    type: "boolean",
    showIf: "options.get('sendSubmissionsTo') === 'custom' && options.get('sendWithJs') === true",
    advanced: !0
  }, {
    name: "successMessage",
    type: "uiBlocks",
    hideFromUI: !0,
    defaultValue: [{
      "@type": "@builder.io/sdk:Element",
      responsiveStyles: {
        large: {
          marginTop: "10px"
        }
      },
      component: {
        name: "Text",
        options: {
          text: "<span>Thanks!</span>"
        }
      }
    }]
  }, {
    name: "validate",
    type: "boolean",
    defaultValue: !0,
    advanced: !0
  }, {
    name: "errorMessagePath",
    type: "text",
    advanced: !0,
    helperText: 'Path to where to get the error message from in a JSON response to display to the user, e.g. "error.message" for a response like { "error": { "message": "this username is taken" }}'
  }, {
    name: "errorMessage",
    type: "uiBlocks",
    hideFromUI: !0,
    defaultValue: [{
      "@type": "@builder.io/sdk:Element",
      responsiveStyles: {
        large: {
          marginTop: "10px"
        }
      },
      bindings: {
        "component.options.text": "state.formErrorMessage || block.component.options.text"
      },
      component: {
        name: "Text",
        options: {
          text: "<span>Form submission error :( Please check your answers and try again</span>"
        }
      }
    }]
  }, {
    name: "sendingMessage",
    type: "uiBlocks",
    hideFromUI: !0,
    defaultValue: [{
      "@type": "@builder.io/sdk:Element",
      responsiveStyles: {
        large: {
          marginTop: "10px"
        }
      },
      component: {
        name: "Text",
        options: {
          text: "<span>Sending...</span>"
        }
      }
    }]
  }, {
    name: "customHeaders",
    type: "map",
    valueType: {
      type: "string"
    },
    advanced: !0,
    showIf: 'options.get("sendSubmissionsTo") === "custom" && options.get("sendWithJs") === true'
  }],
  noWrap: !0,
  canHaveChildren: !0,
  defaultChildren: [{
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        marginTop: "10px"
      }
    },
    component: {
      name: "Text",
      options: {
        text: "<span>Enter your name</span>"
      }
    }
  }, {
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        marginTop: "10px"
      }
    },
    component: {
      name: "Form:Input",
      options: {
        name: "name",
        placeholder: "Jane Doe"
      }
    }
  }, {
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        marginTop: "10px"
      }
    },
    component: {
      name: "Text",
      options: {
        text: "<span>Enter your email</span>"
      }
    }
  }, {
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        marginTop: "10px"
      }
    },
    component: {
      name: "Form:Input",
      options: {
        name: "email",
        placeholder: "jane@doe.com"
      }
    }
  }, {
    "@type": "@builder.io/sdk:Element",
    responsiveStyles: {
      large: {
        marginTop: "10px"
      }
    },
    component: {
      name: "Form:SubmitButton",
      options: {
        text: "Submit"
      }
    }
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0,
    builderContext: !0,
    builderComponents: !0,
    builderLinkComponent: !0
  }
}, Ir = ["production", "qa", "test", "development", "dev", "cdn-qa", "cloud", "fast", "cdn2", "cdn-prod"], $r = () => {
  const e = process.env.NODE_ENV || "production";
  return Ir.includes(e) ? e : "production";
}, Rr = g({
  name: "builder-form-component",
  components: { Blocks: H },
  props: [
    "builderContext",
    "previewState",
    "sendWithJs",
    "sendSubmissionsTo",
    "action",
    "customHeaders",
    "contentType",
    "sendSubmissionsToEmail",
    "name",
    "method",
    "errorMessagePath",
    "resetFormOnSubmit",
    "successUrl",
    "validate",
    "attributes",
    "errorMessage",
    "sendingMessage",
    "successMessage"
  ],
  data() {
    return {
      formState: "unsubmitted",
      responseData: null,
      formErrorMessage: "",
      filterAttrs: V
    };
  },
  methods: {
    mergeNewRootState(e) {
      var n, o;
      const t = {
        ...this.builderContext.rootState,
        ...e
      };
      this.builderContext.rootSetState ? (o = (n = this.builderContext).rootSetState) == null || o.call(n, t) : this.builderContext.rootState = t;
    },
    submissionState() {
      return E() && this.previewState || this.formState;
    },
    onSubmit(e) {
      var n;
      const t = this.sendWithJs || this.sendSubmissionsTo === "email";
      if (this.sendSubmissionsTo === "zapier")
        e.preventDefault();
      else if (t) {
        if (!(this.action || this.sendSubmissionsTo === "email")) {
          e.preventDefault();
          return;
        }
        e.preventDefault();
        const o = e.currentTarget || e.target, r = this.customHeaders || {};
        let s;
        const i = new FormData(o), l = Array.from(o.querySelectorAll("input,select,textarea")).filter((d) => !!d.name).map((d) => {
          let f;
          const v = d.name;
          if (d instanceof HTMLInputElement)
            if (d.type === "radio") {
              if (d.checked)
                return f = d.name, {
                  key: v,
                  value: f
                };
            } else if (d.type === "checkbox")
              f = d.checked;
            else if (d.type === "number" || d.type === "range") {
              const T = d.valueAsNumber;
              isNaN(T) || (f = T);
            } else
              d.type === "file" ? f = d.files : f = d.value;
          else
            f = d.value;
          return {
            key: v,
            value: f
          };
        });
        let a = this.contentType;
        if (this.sendSubmissionsTo === "email" && (a = "multipart/form-data"), Array.from(l).forEach(({ value: d }) => {
          (d instanceof File || Array.isArray(d) && d[0] instanceof File || d instanceof FileList) && (a = "multipart/form-data");
        }), a !== "application/json")
          s = i;
        else {
          const d = {};
          Array.from(l).forEach(({ value: f, key: v }) => {
            ze(d, v, f);
          }), s = JSON.stringify(d);
        }
        a && a !== "multipart/form-data" && (t && ((n = this.action) != null && n.includes("zapier.com")) || (r["content-type"] = a));
        const u = new CustomEvent("presubmit", {
          detail: {
            body: s
          }
        });
        if (this.$refs.formRef && (this.$refs.formRef.dispatchEvent(u), u.defaultPrevented))
          return;
        this.formState = "sending";
        const h = `${$r() === "dev" ? "http://localhost:5000" : "https://builder.io"}/api/v1/form-submit?apiKey=${this.builderContext.apiKey}&to=${btoa(
          this.sendSubmissionsToEmail || ""
        )}&name=${encodeURIComponent(this.name || "")}`, m = this.sendSubmissionsTo === "email" ? h : this.action;
        be(m), fetch(m, {
          body: s,
          headers: r,
          method: this.method || "post"
        }).then(
          async (d) => {
            let f;
            const v = d.headers.get("content-type");
            if (v && v.indexOf("application/json") !== -1 ? f = await d.json() : f = await d.text(), !d.ok && this.errorMessagePath) {
              let T = Fe(f, this.errorMessagePath);
              T && (typeof T != "string" && (T = JSON.stringify(T)), this.formErrorMessage = T, this.mergeNewRootState({
                formErrorMessage: T
              }));
            }
            if (this.responseData = f, this.formState = d.ok ? "success" : "error", d.ok) {
              const T = new CustomEvent("submit:success", {
                detail: {
                  res: d,
                  body: f
                }
              });
              if (this.$refs.formRef) {
                if (this.$refs.formRef.dispatchEvent(T), T.defaultPrevented)
                  return;
                this.resetFormOnSubmit !== !1 && this.$refs.formRef.reset();
              }
              if (this.successUrl)
                if (this.$refs.formRef) {
                  const F = new CustomEvent("route", {
                    detail: {
                      url: this.successUrl
                    }
                  });
                  this.$refs.formRef.dispatchEvent(F), F.defaultPrevented || (location.href = this.successUrl);
                } else
                  location.href = this.successUrl;
            }
          },
          (d) => {
            const f = new CustomEvent("submit:error", {
              detail: {
                error: d
              }
            });
            this.$refs.formRef && (this.$refs.formRef.dispatchEvent(f), f.defaultPrevented) || (this.responseData = d, this.formState = "error");
          }
        );
      }
    }
  }
}), Er = ["validate", "action", "method", "name"], Pr = {
  key: 2,
  class: "builder-form-error-text pre-1hjwvu3r5mj"
};
function Br(e, t, n, o, r, s) {
  const i = k("Blocks");
  return c(), p("form", C({
    validate: e.validate,
    ref: "formRef",
    action: !e.sendWithJs && e.action,
    method: e.method,
    name: e.name,
    onSubmit: t[0] || (t[0] = async (l) => e.onSubmit(l))
  }, { ...e.filterAttrs(e.attributes, "v-on:", !1) }, x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), [
    A(e.$slots, "default", {}, void 0, !0),
    e.submissionState() === "error" ? (c(), y(i, {
      key: 0,
      path: "errorMessage",
      blocks: e.errorMessage,
      context: e.builderContext
    }, null, 8, ["blocks", "context"])) : S("", !0),
    e.submissionState() === "sending" ? (c(), y(i, {
      key: 1,
      path: "sendingMessage",
      blocks: e.sendingMessage,
      context: e.builderContext
    }, null, 8, ["blocks", "context"])) : S("", !0),
    e.submissionState() === "error" && e.responseData ? (c(), p("pre", Pr, ne(JSON.stringify(e.responseData, null, 2)), 1)) : S("", !0),
    e.submissionState() === "success" ? (c(), y(i, {
      key: 3,
      path: "successMessage",
      blocks: e.successMessage,
      context: e.builderContext
    }, null, 8, ["blocks", "context"])) : S("", !0)
  ], 16, Er);
}
const Ar = /* @__PURE__ */ b(Rr, [["render", Br], ["__scopeId", "data-v-4ef43cdd"]]), xr = {
  name: "Form:Input",
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fad6f37889d9e40bbbbc72cdb5875d6ca",
  inputs: [
    {
      name: "type",
      type: "text",
      enum: ["text", "number", "email", "url", "checkbox", "radio", "range", "date", "datetime-local", "search", "tel", "time", "file", "month", "week", "password", "color", "hidden"],
      defaultValue: "text"
    },
    {
      name: "name",
      type: "string",
      required: !0,
      helperText: 'Every input in a form needs a unique name describing what it takes, e.g. "email"'
    },
    {
      name: "placeholder",
      type: "string",
      defaultValue: "Hello there",
      helperText: "Text to display when there is no value"
    },
    // TODO: handle value vs default value automatically like ng-model
    {
      name: "defaultValue",
      type: "string"
    },
    {
      name: "value",
      type: "string",
      advanced: !0
    },
    {
      name: "required",
      type: "boolean",
      helperText: "Is this input required to be filled out to submit a form",
      defaultValue: !1
    }
  ],
  noWrap: !0,
  static: !0,
  defaultStyles: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "3px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ccc"
  }
}, Vr = g({
  name: "builder-form-input-component",
  props: [
    "attributes",
    "defaultValue",
    "placeholder",
    "type",
    "name",
    "value",
    "required"
  ],
  data() {
    return { isEditing: E, filterAttrs: V };
  }
}), Nr = ["placeholder", "type", "name", "value", "defaultValue", "required"];
function Wr(e, t, n, o, r, s) {
  return c(), p("input", C({
    key: e.isEditing() && e.defaultValue ? e.defaultValue : "default-key",
    placeholder: e.placeholder,
    type: e.type,
    name: e.name,
    value: e.value,
    defaultValue: e.defaultValue,
    required: e.required
  }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), null, 16, Nr);
}
const Or = /* @__PURE__ */ b(Vr, [["render", Wr]]), Ur = {
  name: "Form:Select",
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F83acca093fb24aaf94dee136e9a4b045",
  defaultStyles: {
    alignSelf: "flex-start"
  },
  inputs: [{
    name: "options",
    type: "list",
    required: !0,
    subFields: [{
      name: "value",
      type: "text",
      required: !0
    }, {
      name: "name",
      type: "text"
    }],
    defaultValue: [{
      value: "option 1"
    }, {
      value: "option 2"
    }]
  }, {
    name: "name",
    type: "string",
    required: !0,
    helperText: 'Every select in a form needs a unique name describing what it gets, e.g. "email"'
  }, {
    name: "defaultValue",
    type: "string"
  }, {
    name: "value",
    type: "string",
    advanced: !0
  }, {
    name: "required",
    type: "boolean",
    defaultValue: !1
  }],
  static: !0,
  noWrap: !0
}, Dr = g({
  name: "builder-select-component",
  props: ["attributes", "value", "defaultValue", "name", "required", "options"],
  data() {
    return { isEditing: E, filterAttrs: V };
  }
}), Fr = ["value", "defaultValue", "name", "required"], Lr = ["value"];
function Mr(e, t, n, o, r, s) {
  return c(), p("select", C({
    value: e.value,
    key: e.isEditing() && e.defaultValue ? e.defaultValue : "default-key",
    defaultValue: e.defaultValue,
    name: e.name,
    required: e.required
  }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), [
    (c(!0), p($, null, N(e.options, (i, l) => (c(), p("option", {
      key: `${i.name}-${l}`,
      value: i.value
    }, ne(i.name || i.value), 9, Lr))), 128))
  ], 16, Fr);
}
const Hr = /* @__PURE__ */ b(Dr, [["render", Mr]]), jr = {
  name: "Form:SubmitButton",
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fdf2820ffed1f4349a94c40b3221f5b98",
  defaultStyles: {
    appearance: "none",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: "#3898EC",
    color: "white",
    borderRadius: "4px",
    cursor: "pointer"
  },
  inputs: [{
    name: "text",
    type: "text",
    defaultValue: "Click me"
  }],
  static: !0,
  noWrap: !0
  // TODO: optional children? maybe as optional form input
  // that only shows if advanced setting is flipped
  // TODO: defaultChildren
  // canHaveChildren: true,
}, Kr = g({
  name: "builder-submit-button",
  props: ["attributes", "text"],
  data() {
    return { filterAttrs: V };
  }
});
function zr(e, t, n, o, r, s) {
  return c(), p("button", C({ type: "submit" }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), ne(e.text), 17);
}
const qr = /* @__PURE__ */ b(Kr, [["render", zr]]), Jr = {
  name: "Form:TextArea",
  image: "https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Ff74a2f3de58c4c3e939204e5b6b8f6c3",
  inputs: [{
    advanced: !0,
    name: "value",
    type: "string"
  }, {
    name: "name",
    type: "string",
    required: !0,
    helperText: 'Every input in a form needs a unique name describing what it gets, e.g. "email"'
  }, {
    name: "defaultValue",
    type: "string"
  }, {
    name: "placeholder",
    type: "string",
    defaultValue: "Hello there"
  }, {
    name: "required",
    type: "boolean",
    defaultValue: !1
  }],
  defaultStyles: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "3px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ccc"
  },
  static: !0,
  noWrap: !0
}, Gr = g({
  name: "builder-textarea",
  props: [
    "attributes",
    "placeholder",
    "name",
    "value",
    "defaultValue",
    "required"
  ],
  data() {
    return { filterAttrs: V };
  }
}), Yr = ["placeholder", "name", "value", "defaultValue", "required"];
function Xr(e, t, n, o, r, s) {
  return c(), p("textarea", C({
    placeholder: e.placeholder,
    name: e.name,
    value: e.value,
    defaultValue: e.defaultValue,
    required: e.required
  }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), null, 16, Yr);
}
const Qr = /* @__PURE__ */ b(Gr, [["render", Xr]]), Zr = {
  // friendlyName?
  name: "Raw:Img",
  hideFromInsertMenu: !0,
  image: "https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-insert_photo-24px.svg?alt=media&token=4e5d0ef4-f5e8-4e57-b3a9-38d63a9b9dc4",
  inputs: [{
    name: "image",
    bubble: !0,
    type: "file",
    allowedFileTypes: we,
    required: !0
  }],
  noWrap: !0,
  static: !0
}, _r = g({
  name: "builder-img-component",
  props: [
    "backgroundSize",
    "backgroundPosition",
    "imgSrc",
    "altText",
    "image",
    "attributes"
  ],
  data() {
    return { isEditing: E, filterAttrs: V };
  }
}), es = ["alt", "src"];
function ts(e, t, n, o, r, s) {
  return c(), p("img", C({
    style: {
      objectFit: e.backgroundSize || "cover",
      objectPosition: e.backgroundPosition || "center"
    },
    key: e.isEditing() && e.imgSrc || "default-key",
    alt: e.altText,
    src: e.imgSrc || e.image
  }, e.filterAttrs(e.attributes, "v-on:", !1), x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), null, 16, es);
}
const ns = /* @__PURE__ */ b(_r, [["render", ts]]), os = {
  name: "Video",
  canHaveChildren: !0,
  defaultStyles: {
    minHeight: "20px",
    minWidth: "20px"
  },
  image: "https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-videocam-24px%20(1).svg?alt=media&token=49a84e4a-b20e-4977-a650-047f986874bb",
  inputs: [{
    name: "video",
    type: "file",
    allowedFileTypes: Jo,
    bubble: !0,
    defaultValue: "https://cdn.builder.io/o/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd27731a526464deba0016216f5f9e570%2Fcompressed?apiKey=YJIGb4i01jvw0SRdL5Bt&token=d27731a526464deba0016216f5f9e570&alt=media&optimized=true",
    required: !0
  }, {
    name: "posterImage",
    type: "file",
    allowedFileTypes: we,
    helperText: "Image to show before the video plays"
  }, {
    name: "autoPlay",
    type: "boolean",
    defaultValue: !0
  }, {
    name: "controls",
    type: "boolean",
    defaultValue: !1
  }, {
    name: "muted",
    type: "boolean",
    defaultValue: !0
  }, {
    name: "loop",
    type: "boolean",
    defaultValue: !0
  }, {
    name: "playsInline",
    type: "boolean",
    defaultValue: !0
  }, {
    name: "fit",
    type: "text",
    defaultValue: "cover",
    enum: ["contain", "cover", "fill", "auto"]
  }, {
    name: "preload",
    type: "text",
    defaultValue: "metadata",
    enum: ["auto", "metadata", "none"]
  }, {
    name: "fitContent",
    type: "boolean",
    helperText: "When child blocks are provided, fit to them instead of using the aspect ratio",
    defaultValue: !0,
    advanced: !0
  }, {
    name: "position",
    type: "text",
    defaultValue: "center",
    enum: ["center", "top", "left", "right", "bottom", "top left", "top right", "bottom left", "bottom right"]
  }, {
    name: "height",
    type: "number",
    advanced: !0
  }, {
    name: "width",
    type: "number",
    advanced: !0
  }, {
    name: "aspectRatio",
    type: "number",
    advanced: !0,
    defaultValue: 0.7004048582995948
  }, {
    name: "lazyLoad",
    type: "boolean",
    helperText: 'Load this video "lazily" - as in only when a user scrolls near the video. Recommended for optmized performance and bandwidth consumption',
    defaultValue: !0,
    advanced: !0
  }],
  shouldReceiveBuilderProps: {
    builderBlock: !0
  }
}, rs = g({
  name: "builder-video",
  props: [
    "lazyLoad",
    "autoPlay",
    "muted",
    "controls",
    "loop",
    "playsInline",
    "preload",
    "attributes",
    "fit",
    "position",
    "aspectRatio",
    "posterImage",
    "video",
    "fitContent",
    "builderBlock"
  ],
  data() {
    return { lazyVideoObserver: void 0 };
  },
  mounted() {
    if (this.lazyLoad) {
      const e = new IntersectionObserver(function(t) {
        t.forEach(function(n) {
          if (!n.isIntersecting)
            return;
          const o = n.target;
          try {
            Array.from(o.children).filter(
              (r) => r instanceof HTMLElement && r.tagName === "SOURCE"
            ).forEach((r) => {
              const s = r.dataset.src;
              s && (r.src = s);
            }), o.load(), e.unobserve(o);
          } catch (r) {
            console.error("Error loading lazy video:", r);
          }
        });
      });
      this.$refs.videoRef && e.observe(this.$refs.videoRef), this.lazyVideoObserver = e;
    }
  },
  unmounted() {
    this.lazyVideoObserver && this.lazyVideoObserver.disconnect();
  },
  computed: {
    videoProps() {
      return {
        ...this.autoPlay === !0 ? {
          autoPlay: !0
        } : {},
        ...this.muted === !0 ? {
          muted: !0
        } : {},
        ...this.controls === !0 ? {
          controls: !0
        } : {},
        ...this.loop === !0 ? {
          loop: !0
        } : {},
        ...this.playsInline === !0 ? {
          playsInline: !0
        } : {}
      };
    },
    spreadProps() {
      return {
        ...this.videoProps
      };
    }
  }
}), ss = { style: {
  position: "relative"
} }, is = ["preload", "poster"], as = {
  key: 1,
  style: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  }
}, ls = {
  key: 2,
  style: {
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  }
};
function cs(e, t, n, o, r, s) {
  var i, l, a, u, h, m, d;
  return c(), p("div", ss, [
    M("video", C({
      class: "builder-video",
      ref: "videoRef",
      preload: e.lazyLoad ? "none" : e.preload || "metadata",
      style: {
        width: "100%",
        height: "100%",
        ...(i = e.attributes) == null ? void 0 : i.style,
        objectFit: e.fit,
        objectPosition: e.position,
        // Hack to get object fit to work as expected and
        // not have the video overflow
        borderRadius: "1px",
        ...e.aspectRatio ? {
          position: "absolute"
        } : null
      },
      poster: e.posterImage
    }, e.spreadProps), [
      M("source", C(
        { type: "video/mp4" },
        e.lazyLoad ? {
          "data-src": e.video
        } : {
          src: e.video
        }
      ), null, 16)
    ], 16, is),
    e.aspectRatio && !(e.fitContent && ((a = (l = e.builderBlock) == null ? void 0 : l.children) != null && a.length)) ? (c(), p("div", {
      key: 0,
      style: U({
        width: "100%",
        paddingTop: e.aspectRatio * 100 + "%",
        pointerEvents: "none",
        fontSize: "0px"
      })
    }, null, 4)) : S("", !0),
    (h = (u = e.builderBlock) == null ? void 0 : u.children) != null && h.length && e.fitContent ? (c(), p("div", as, [
      A(e.$slots, "default")
    ])) : S("", !0),
    (d = (m = e.builderBlock) == null ? void 0 : m.children) != null && d.length && !e.fitContent ? (c(), p("div", ls, [
      A(e.$slots, "default")
    ])) : S("", !0)
  ]);
}
const ds = /* @__PURE__ */ b(rs, [["render", cs]]), us = () => [{
  component: gr,
  ...pr
}, {
  component: wr,
  ...br
}, {
  component: Ar,
  ...Tr
}, {
  component: Or,
  ...xr
}, {
  component: qr,
  ...jr
}, {
  component: Hr,
  ...Ur
}, {
  component: Qr,
  ...Jr
}, {
  component: ns,
  ...Zr
}, {
  component: ds,
  ...os
}], Ne = () => [{
  component: At,
  ...Ko
}, {
  component: wo,
  ...zo
}, {
  component: $o,
  ...qo
}, {
  component: No,
  ...Go
}, {
  component: Uo,
  ...Yo
}, {
  component: _o,
  ...Xo
}, {
  component: Ys,
  ...er
}, {
  component: ur,
  ...ar
}, {
  component: ir,
  ...tr
}, {
  component: jo,
  ...Do
}, ...us()], ps = `function updateCookiesAndStyles(contentId, variants, isHydrationTarget, isAngularSDK) {
  function getAndSetVariantId() {
    function setCookie(name, value, days) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/' + '; Secure; SameSite=None';
    }
    function getCookie(name) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    const cookieName = \`builder.tests.\${contentId}\`;
    const variantInCookie = getCookie(cookieName);
    const availableIDs = variants.map(vr => vr.id).concat(contentId);
    if (variantInCookie && availableIDs.includes(variantInCookie)) {
      return variantInCookie;
    }
    let n = 0;
    const random = Math.random();
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      const testRatio = variant.testRatio;
      n += testRatio;
      if (random < n) {
        setCookie(cookieName, variant.id);
        return variant.id;
      }
    }
    setCookie(cookieName, contentId);
    return contentId;
  }
  const winningVariantId = getAndSetVariantId();
  let styleEl = document.currentScript?.previousElementSibling;
  if (isAngularSDK) {
    styleEl = document.currentScript?.parentElement?.previousElementSibling?.querySelector('style');
  }
  if (isHydrationTarget) {
    styleEl.remove();
    const thisScriptEl = document.currentScript;
    thisScriptEl?.remove();
  } else {
    const newStyleStr = variants.concat({
      id: contentId
    }).filter(variant => variant.id !== winningVariantId).map(value => {
      return \`.variant-\${value.id} {  display: none; }
        \`;
    }).join('');
    styleEl.innerHTML = newStyleStr;
  }
}`, ms = `function updateVariantVisibility(variantContentId, defaultContentId, isHydrationTarget) {
  if (!navigator.cookieEnabled) {
    return;
  }
  function getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  const cookieName = \`builder.tests.\${defaultContentId}\`;
  const winningVariant = getCookie(cookieName);
  const parentDiv = document.currentScript?.parentElement;
  const isDefaultContent = variantContentId === defaultContentId;
  const isWinningVariant = winningVariant === variantContentId;
  if (isWinningVariant && !isDefaultContent) {
    parentDiv?.removeAttribute('hidden');
    parentDiv?.removeAttribute('aria-hidden');
  } else if (!isWinningVariant && isDefaultContent) {
    parentDiv?.setAttribute('hidden', 'true');
    parentDiv?.setAttribute('aria-hidden', 'true');
  }
  if (isHydrationTarget) {
    if (!isWinningVariant) {
      parentDiv?.remove();
    }
    const thisScriptEl = document.currentScript;
    thisScriptEl?.remove();
  }
  return;
}`, yt = "builderIoAbTest", kt = "builderIoRenderContent", Z = (e) => Object.values((e == null ? void 0 : e.variations) || {}).map((t) => ({
  ...t,
  testVariationId: t.id,
  id: e == null ? void 0 : e.id
})), hs = ({
  canTrack: e,
  content: t
}) => !(!(Z(t).length > 0) || !e), fs = (e) => e === "react" || e === "reactNative", gs = R === "angular", vt = fs(R), bs = () => `
  window.${yt} = ${ps}
  window.${kt} = ${ms}
  `, ys = (e, t) => `
  window.${yt}(
    "${t}",${JSON.stringify(e)}, ${vt}, ${gs}
  )`, ks = ({
  contentId: e,
  variationId: t
}) => `window.${kt}(
    "${t}", "${e}", ${vt}
  )`;
function We(e) {
  return Math.round(e * 1e3) / 1e3;
}
const vs = (e, t, n = !0) => {
  if (!(e instanceof HTMLElement))
    return null;
  let o = n ? e : e.parentElement;
  do {
    if (!o)
      return null;
    if (t(o))
      return o;
  } while (o = o.parentElement);
  return null;
}, Ss = (e) => vs(e, (t) => {
  const n = t.getAttribute("builder-id") || t.id;
  return (n == null ? void 0 : n.indexOf("builder-")) === 0;
}), Oe = ({
  event: e,
  target: t
}) => {
  const n = t.getBoundingClientRect(), o = e.clientX - n.left, r = e.clientY - n.top, s = We(o / n.width), i = We(r / n.height);
  return {
    x: s,
    y: i
  };
}, Cs = (e) => {
  const t = e.target, n = t && Ss(t), o = (n == null ? void 0 : n.getAttribute("builder-id")) || (n == null ? void 0 : n.id);
  return {
    targetBuilderElement: o || void 0,
    metadata: {
      targetOffset: t ? Oe({
        event: e,
        target: t
      }) : void 0,
      builderTargetOffset: n ? Oe({
        event: e,
        target: n
      }) : void 0,
      builderElementIndex: n && o ? [].slice.call(document.getElementsByClassName(o)).indexOf(n) : void 0
    }
  };
}, ws = ["svelte", "qwik", "vue"], Ts = () => ws.includes(R) && (E() || qe()), Is = (e) => {
  var s;
  const t = e.family + (e.kind && !e.kind.includes("#") ? ", " + e.kind : ""), n = t.split(",")[0], o = e.fileUrl ?? ((s = e == null ? void 0 : e.files) == null ? void 0 : s.regular);
  let r = "";
  if (o && t && n && (r += `
@font-face {
font-family: "${t}";
src: local("${n}"), url('${o}') format('woff2');
font-display: fallback;
font-weight: 400;
}
      `.trim()), e.files)
    for (const i in e.files) {
      if (!(String(Number(i)) === i))
        continue;
      const a = e.files[i];
      a && a !== o && (r += `
@font-face {
font-family: "${t}";
src: url('${a}') format('woff2');
font-display: fallback;
font-weight: ${i};
}
        `.trim());
    }
  return r;
}, $s = ({
  customFonts: e
}) => {
  var t;
  return ((t = e == null ? void 0 : e.map((n) => Is(n))) == null ? void 0 : t.join(" ")) || "";
}, Rs = ({
  cssCode: e,
  contentId: t
}) => e ? t ? (e == null ? void 0 : e.replace(/&/g, `div[builder-content-id="${t}"]`)) || "" : e : "", Es = `
.builder-button {
  all: unset;
}

.builder-text > p:first-of-type, .builder-text > .builder-paragraph:first-of-type {
  margin: 0;
}
.builder-text > p, .builder-text > .builder-paragraph {
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: inherit;
  font-family: inherit;
}
`, Ps = (e) => e ? "" : Es, Bs = (e) => `variant-${e}`, As = g({
  name: "enable-editor",
  components: { DynamicDiv: X },
  props: [
    "isNestedRender",
    "builderContextSignal",
    "canTrack",
    "apiKey",
    "apiHost",
    "locale",
    "enrich",
    "trustedHosts",
    "model",
    "content",
    "context",
    "data",
    "showContent",
    "contentWrapper",
    "contentWrapperProps"
  ],
  data() {
    return {
      hasExecuted: !1,
      ContentWrapper: this.contentWrapper || "div",
      httpReqsData: {},
      httpReqsPending: {},
      clicked: !1,
      builderContext: de,
      needsElementRefDivForEditing: Ts,
      getWrapperClassName: Bs
    };
  },
  provide() {
    const e = this;
    return {
      [de.key]: e.builderContextSignal
    };
  },
  created() {
    this.runHttpRequests(), this.emitStateUpdate();
  },
  mounted() {
    var e, t, n;
    if (w()) {
      E() && !this.isNestedRender && this.$refs.elementRef && this.$refs.elementRef.dispatchEvent(
        new CustomEvent("initeditingbldr")
      );
      const o = this.builderContextSignal.content && G(this.canTrack), r = oe({
        name: `builder.tests.${(e = this.builderContextSignal.content) == null ? void 0 : e.id}`,
        canTrack: !0
      }), s = (t = this.builderContextSignal.content) == null ? void 0 : t.testVariationId;
      if (o && s === r) {
        const i = (n = this.builderContextSignal.content) == null ? void 0 : n.id, l = this.apiKey;
        pe({
          apiHost: this.apiHost,
          type: "impression",
          canTrack: !0,
          contentId: i,
          apiKey: l,
          variationId: r !== i ? r : void 0
        });
      }
      qe() && !E() && this.$refs.elementRef && this.$refs.elementRef.dispatchEvent(
        new CustomEvent("initpreviewingbldr")
      );
    }
  },
  watch: {
    onUpdateHook0: {
      handler() {
        this.content && this.mergeNewContent(this.content);
      },
      immediate: !0
    },
    onUpdateHook1: {
      handler() {
        this.emitStateUpdate();
      },
      immediate: !0
    },
    onUpdateHook2: {
      handler() {
        this.data && this.mergeNewRootState(this.data);
      },
      immediate: !0
    },
    onUpdateHook3: {
      handler() {
        this.locale && this.mergeNewRootState({
          locale: this.locale
        });
      },
      immediate: !0
    }
  },
  unmounted() {
    w() && (window.removeEventListener("message", this.processMessage), window.removeEventListener(
      "builder:component:stateChangeListenerActivated",
      this.emitStateUpdate
    ));
  },
  computed: {
    showContentProps() {
      return this.showContent ? {} : {
        hidden: !0,
        "aria-hidden": !0
      };
    },
    onUpdateHook0() {
      return {
        0: this.content
      };
    },
    onUpdateHook1() {
      return {
        0: this.builderContextSignal.rootState
      };
    },
    onUpdateHook2() {
      return {
        0: this.data
      };
    },
    onUpdateHook3() {
      return {
        0: this.locale
      };
    }
  },
  methods: {
    mergeNewRootState(e) {
      var n, o;
      const t = {
        ...this.builderContextSignal.rootState,
        ...e
      };
      this.builderContextSignal.rootSetState ? (o = (n = this.builderContextSignal).rootSetState) == null || o.call(n, t) : this.builderContextSignal.rootState = t;
    },
    mergeNewContent(e) {
      var n, o, r, s, i;
      const t = {
        ...this.builderContextSignal.content,
        ...e,
        data: {
          ...(n = this.builderContextSignal.content) == null ? void 0 : n.data,
          ...e == null ? void 0 : e.data
        },
        meta: {
          ...(o = this.builderContextSignal.content) == null ? void 0 : o.meta,
          ...e == null ? void 0 : e.meta,
          breakpoints: ((r = e == null ? void 0 : e.meta) == null ? void 0 : r.breakpoints) || ((i = (s = this.builderContextSignal.content) == null ? void 0 : s.meta) == null ? void 0 : i.breakpoints)
        }
      };
      this.builderContextSignal.content = t;
    },
    processMessage(e) {
      return ot({
        model: this.model,
        trustedHosts: this.trustedHosts,
        callbacks: {
          configureSdk: (t) => {
            var r;
            const { breakpoints: n, contentId: o } = t;
            !o || o !== ((r = this.builderContextSignal.content) == null ? void 0 : r.id) || n && this.mergeNewContent({
              meta: {
                breakpoints: n
              }
            });
          },
          animation: (t) => {
            ut(t);
          },
          contentUpdate: (t) => {
            this.mergeNewContent(t);
          },
          stateUpdate: (t) => {
            this.mergeNewRootState(t);
          }
        }
      })(e);
    },
    onClick(e) {
      var t, n;
      if (this.builderContextSignal.content) {
        const o = (t = this.builderContextSignal.content) == null ? void 0 : t.testVariationId, r = (n = this.builderContextSignal.content) == null ? void 0 : n.id;
        pe({
          apiHost: this.apiHost,
          type: "click",
          canTrack: G(this.canTrack),
          contentId: r,
          apiKey: this.apiKey,
          variationId: o !== r ? o : void 0,
          ...Cs(e),
          unique: !this.clicked
        });
      }
      this.clicked || (this.clicked = !0);
    },
    runHttpRequests() {
      var t, n;
      const e = ((n = (t = this.builderContextSignal.content) == null ? void 0 : t.data) == null ? void 0 : n.httpRequests) ?? {};
      Object.entries(e).forEach(([o, r]) => {
        if (!r || this.httpReqsPending[o] || this.httpReqsData[o] && !E())
          return;
        this.httpReqsPending[o] = !0;
        const s = r.replace(
          /{{([^}]+)}}/g,
          (i, l) => String(
            Y({
              code: l,
              context: this.context || {},
              localState: void 0,
              rootState: this.builderContextSignal.rootState,
              rootSetState: this.builderContextSignal.rootSetState
            })
          )
        );
        be(s), fetch(s).then((i) => i.json()).then((i) => {
          this.mergeNewRootState({
            [o]: i
          }), this.httpReqsData[o] = !0;
        }).catch((i) => {
          console.error("error fetching dynamic data", r, i);
        }).finally(() => {
          this.httpReqsPending[o] = !1;
        });
      });
    },
    emitStateUpdate() {
      E() && window.dispatchEvent(
        new CustomEvent(
          "builder:component:stateChange",
          {
            detail: {
              state: fe(this.builderContextSignal.rootState),
              ref: {
                name: this.model
              }
            }
          }
        )
      );
    },
    elementRef_onIniteditingbldr(e) {
      window.addEventListener("message", this.processMessage), In(), nt({
        ...this.locale ? {
          locale: this.locale
        } : {},
        ...this.enrich ? {
          enrich: this.enrich
        } : {},
        ...this.trustedHosts ? {
          trustedHosts: this.trustedHosts
        } : {},
        modelName: this.model ?? "",
        apiKey: this.apiKey
      }), Object.values(
        this.builderContextSignal.componentInfos
      ).forEach((t) => {
        var n, o;
        if (!((n = t.models) != null && n.length) || t.models.includes(this.model)) {
          const r = on(t);
          (o = window.parent) == null || o.postMessage(r, "*");
        }
      }), window.addEventListener(
        "builder:component:stateChangeListenerActivated",
        this.emitStateUpdate
      );
    },
    elementRef_onInitpreviewingbldr(e) {
      var s;
      const t = new URL(location.href).searchParams, n = t.get("builder.preview"), o = t.get(
        `builder.overrides.${n}`
      ), r = t.get("apiKey") || t.get("builder.space");
      (n === "BUILDER_STUDIO" || n === this.model && r === this.apiKey && (!this.content || o === this.content.id)) && ve({
        model: this.model,
        apiKey: this.apiKey,
        apiVersion: this.builderContextSignal.apiVersion,
        ...n === "BUILDER_STUDIO" && ((s = this.context) != null && s.symbolId) ? {
          query: {
            id: this.context.symbolId
          }
        } : {}
      }).then((i) => {
        i && this.mergeNewContent(i);
      });
    }
  }
});
function xs(e, t, n, o, r, s) {
  var i, l, a;
  return e.builderContextSignal.content || e.needsElementRefDivForEditing() ? (c(), y(z(e.ContentWrapper), C({
    key: 0,
    ref: "elementRef",
    onClick: (u) => e.onClick(u),
    "builder-content-id": (i = e.builderContextSignal.content) == null ? void 0 : i.id,
    "builder-model": e.model,
    className: e.getWrapperClassName(((l = e.content) == null ? void 0 : l.testVariationId) || ((a = e.content) == null ? void 0 : a.id)),
    style: {
      display: !e.builderContextSignal.content && e.needsElementRefDivForEditing() ? "none" : void 0
    },
    onIniteditingbldr: (u) => e.elementRef_onIniteditingbldr(u),
    onInitpreviewingbldr: (u) => e.elementRef_onInitpreviewingbldr(u)
  }, { ...e.showContentProps, ...e.contentWrapperProps }), {
    default: W(() => [
      A(e.$slots, "default")
    ]),
    _: 3
  }, 16, ["onClick", "builder-content-id", "builder-model", "className", "style", "onIniteditingbldr", "onInitpreviewingbldr"])) : S("", !0);
}
const Vs = /* @__PURE__ */ b(As, [["render", xs]]), Ns = g({
  name: "content-styles",
  components: { InlinedStyles: Ce },
  props: ["cssCode", "contentId", "customFonts", "isNestedRender", "nonce"],
  data() {
    return {
      injectedStyles: `
${Rs({
        cssCode: this.cssCode,
        contentId: this.contentId
      })}
${$s({
        customFonts: this.customFonts
      })}
${Ps(this.isNestedRender)}
`.trim()
    };
  }
});
function Ws(e, t, n, o, r, s) {
  const i = k("InlinedStyles");
  return c(), y(i, {
    id: "builderio-content",
    styles: e.injectedStyles,
    nonce: e.nonce
  }, null, 8, ["styles", "nonce"]);
}
const Os = /* @__PURE__ */ b(Ns, [["render", Ws]]), Us = ({
  content: e,
  data: t,
  locale: n
}) => {
  var s, i, l;
  const o = {}, r = ((s = e == null ? void 0 : e.data) == null ? void 0 : s.state) || {};
  return (l = (i = e == null ? void 0 : e.data) == null ? void 0 : i.inputs) == null || l.forEach((a) => {
    a.name && a.defaultValue !== void 0 && (o[a.name] = a.defaultValue);
  }), {
    ...o,
    ...r,
    ...t,
    ...n ? {
      locale: n
    } : {}
  };
}, Ds = ({
  content: e,
  data: t
}) => e ? {
  ...e,
  data: {
    ...e == null ? void 0 : e.data,
    ...t
  },
  meta: e == null ? void 0 : e.meta
} : void 0, Fs = Tt, Ls = g({
  name: "content-component",
  components: {
    EnableEditor: Vs,
    InlinedScript: bt,
    ContentStyles: Os,
    Blocks: H,
    DynamicDiv: X
  },
  props: [
    "apiKey",
    "context",
    "content",
    "customComponents",
    "data",
    "locale",
    "canTrack",
    "apiVersion",
    "blocksWrapper",
    "blocksWrapperProps",
    "nonce",
    "model",
    "apiHost",
    "enrich",
    "showContent",
    "contentWrapper",
    "contentWrapperProps",
    "trustedHosts",
    "isNestedRender",
    "isSsrAbTest",
    "linkComponent"
  ],
  data() {
    var e, t;
    return {
      scriptStr: ks({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        variationId: (e = this.content) == null ? void 0 : e.testVariationId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        contentId: (t = this.content) == null ? void 0 : t.id
      }),
      registeredComponents: [
        ...Ne(),
        ...this.customComponents || []
      ].reduce(
        (n, { component: o, ...r }) => ({
          ...n,
          [r.name]: {
            component: Fs(o),
            ...te(r)
          }
        }),
        {}
      ),
      builderContextSignal: {
        content: Ds({
          content: this.content,
          data: this.data
        }),
        localState: void 0,
        rootState: Us({
          content: this.content,
          data: this.data,
          locale: this.locale
        }),
        rootSetState: this.contentSetState,
        context: this.context || {},
        canTrack: this.canTrack,
        apiKey: this.apiKey,
        apiVersion: this.apiVersion,
        componentInfos: [
          ...Ne(),
          ...this.customComponents || []
        ].reduce(
          (n, { component: o, ...r }) => ({
            ...n,
            [r.name]: te(r)
          }),
          {}
        ),
        inheritedStyles: {},
        BlocksWrapper: this.blocksWrapper || "div",
        BlocksWrapperProps: this.blocksWrapperProps || {},
        nonce: this.nonce || "",
        model: this.model
      },
      TARGET: R
    };
  },
  provide() {
    const e = this;
    return {
      [De.key]: {
        registeredComponents: e.registeredComponents
      }
    };
  },
  created() {
    var t, n;
    this.apiKey || P.error(
      "No API key provided to `Content` component. This can cause issues. Please provide an API key using the `apiKey` prop."
    );
    const e = (n = (t = this.builderContextSignal.content) == null ? void 0 : t.data) == null ? void 0 : n.jsCode;
    e && Y({
      code: e,
      context: this.context || {},
      localState: void 0,
      rootState: this.builderContextSignal.rootState,
      rootSetState: (o) => {
        this.builderContextSignal.rootState = o;
      },
      isExpression: !1
    });
  },
  methods: {
    contentSetState(e) {
      this.builderContextSignal.rootState = e;
    }
  }
});
function Ms(e, t, n, o, r, s) {
  const i = k("InlinedScript"), l = k("ContentStyles"), a = k("Blocks"), u = k("EnableEditor");
  return c(), y(u, C({
    apiHost: e.apiHost,
    nonce: e.nonce,
    content: e.content,
    data: e.data,
    model: e.model,
    context: e.context,
    apiKey: e.apiKey,
    canTrack: e.canTrack,
    locale: e.locale,
    enrich: e.enrich,
    showContent: e.showContent,
    builderContextSignal: e.builderContextSignal,
    contentWrapper: e.contentWrapper,
    contentWrapperProps: e.contentWrapperProps,
    trustedHosts: e.trustedHosts,
    isNestedRender: e.isNestedRender
  }, {}), {
    default: W(() => {
      var h, m, d, f, v, T, F;
      return [
        e.isSsrAbTest ? (c(), y(i, {
          key: 0,
          id: "builderio-variant-visibility",
          scriptStr: e.scriptStr,
          nonce: e.nonce || ""
        }, null, 8, ["scriptStr", "nonce"])) : S("", !0),
        e.TARGET !== "reactNative" ? (c(), y(l, {
          key: 1,
          nonce: e.nonce || "",
          isNestedRender: e.isNestedRender,
          contentId: (h = e.builderContextSignal.content) == null ? void 0 : h.id,
          cssCode: (d = (m = e.builderContextSignal.content) == null ? void 0 : m.data) == null ? void 0 : d.cssCode,
          customFonts: (v = (f = e.builderContextSignal.content) == null ? void 0 : f.data) == null ? void 0 : v.customFonts
        }, null, 8, ["nonce", "isNestedRender", "contentId", "cssCode", "customFonts"])) : S("", !0),
        B(a, {
          blocks: (F = (T = e.builderContextSignal.content) == null ? void 0 : T.data) == null ? void 0 : F.blocks,
          context: e.builderContextSignal,
          registeredComponents: e.registeredComponents,
          linkComponent: e.linkComponent
        }, null, 8, ["blocks", "context", "registeredComponents", "linkComponent"])
      ];
    }),
    _: 1
  }, 16, ["apiHost", "nonce", "content", "data", "model", "context", "apiKey", "canTrack", "locale", "enrich", "showContent", "builderContextSignal", "contentWrapper", "contentWrapperProps", "trustedHosts", "isNestedRender"]);
}
const Hs = /* @__PURE__ */ b(Ls, [["render", Ms]]), js = g({
  name: "content-variants",
  components: {
    InlinedScript: bt,
    InlinedStyles: Ce,
    ContentComponent: Hs
  },
  props: [
    "canTrack",
    "content",
    "isNestedRender",
    "nonce",
    "apiHost",
    "model",
    "data",
    "context",
    "apiKey",
    "apiVersion",
    "customComponents",
    "linkComponent",
    "locale",
    "enrich",
    "blocksWrapper",
    "blocksWrapperProps",
    "contentWrapper",
    "contentWrapperProps",
    "trustedHosts"
  ],
  data() {
    return {
      shouldRenderVariants: hs({
        canTrack: G(this.canTrack),
        content: this.content
      }),
      TARGET: R,
      getInitVariantsFnsScriptString: bs,
      getVariants: Z
    };
  },
  mounted() {
  },
  computed: {
    updateCookieAndStylesScriptStr() {
      var e;
      return ys(
        Z(this.content).map((t) => ({
          id: t.testVariationId,
          testRatio: t.testRatio
        })),
        ((e = this.content) == null ? void 0 : e.id) || ""
      );
    },
    hideVariantsStyleString() {
      return Z(this.content).map((e) => `.variant-${e.testVariationId} { display: none; } `).join("");
    },
    defaultContent() {
      var e;
      return this.shouldRenderVariants ? {
        ...this.content,
        testVariationId: (e = this.content) == null ? void 0 : e.id
      } : An({
        item: this.content,
        canTrack: G(this.canTrack)
      });
    }
  }
});
function Ks(e, t, n, o, r, s) {
  const i = k("InlinedScript"), l = k("InlinedStyles"), a = k("ContentComponent");
  return c(), p($, null, [
    !e.isNestedRender && e.TARGET !== "reactNative" ? (c(), y(i, {
      key: 0,
      id: "builderio-init-variants-fns",
      scriptStr: e.getInitVariantsFnsScriptString(),
      nonce: e.nonce || ""
    }, null, 8, ["scriptStr", "nonce"])) : S("", !0),
    e.shouldRenderVariants ? (c(), p($, { key: 1 }, [
      B(l, {
        id: "builderio-variants",
        styles: e.hideVariantsStyleString,
        nonce: e.nonce || ""
      }, null, 8, ["styles", "nonce"]),
      B(i, {
        id: "builderio-variants-visibility",
        scriptStr: e.updateCookieAndStylesScriptStr,
        nonce: e.nonce || ""
      }, null, 8, ["scriptStr", "nonce"]),
      (c(!0), p($, null, N(e.getVariants(e.content), (u, h) => (c(), y(a, C({
        key: u.testVariationId,
        apiHost: e.apiHost,
        isNestedRender: e.isNestedRender,
        nonce: e.nonce,
        content: u,
        showContent: !1,
        model: e.model,
        data: e.data,
        context: e.context,
        apiKey: e.apiKey,
        apiVersion: e.apiVersion,
        customComponents: e.customComponents,
        linkComponent: e.linkComponent,
        canTrack: e.canTrack,
        locale: e.locale,
        enrich: e.enrich,
        isSsrAbTest: e.shouldRenderVariants,
        blocksWrapper: e.blocksWrapper,
        blocksWrapperProps: e.blocksWrapperProps,
        contentWrapper: e.contentWrapper,
        contentWrapperProps: e.contentWrapperProps,
        trustedHosts: e.trustedHosts
      }, {}), null, 16, ["apiHost", "isNestedRender", "nonce", "content", "model", "data", "context", "apiKey", "apiVersion", "customComponents", "linkComponent", "canTrack", "locale", "enrich", "isSsrAbTest", "blocksWrapper", "blocksWrapperProps", "contentWrapper", "contentWrapperProps", "trustedHosts"]))), 128))
    ], 64)) : S("", !0),
    B(a, C({
      apiHost: e.apiHost,
      nonce: e.nonce,
      isNestedRender: e.isNestedRender,
      content: e.defaultContent,
      showContent: !0,
      model: e.model,
      data: e.data,
      context: e.context,
      apiKey: e.apiKey,
      apiVersion: e.apiVersion,
      customComponents: e.customComponents,
      linkComponent: e.linkComponent,
      canTrack: e.canTrack,
      locale: e.locale,
      enrich: e.enrich,
      isSsrAbTest: e.shouldRenderVariants,
      blocksWrapper: e.blocksWrapper,
      blocksWrapperProps: e.blocksWrapperProps,
      contentWrapper: e.contentWrapper,
      contentWrapperProps: e.contentWrapperProps,
      trustedHosts: e.trustedHosts
    }, {
      key: e.shouldRenderVariants.toString()
    }), null, 16, ["apiHost", "nonce", "isNestedRender", "content", "model", "data", "context", "apiKey", "apiVersion", "customComponents", "linkComponent", "canTrack", "locale", "enrich", "isSsrAbTest", "blocksWrapper", "blocksWrapperProps", "contentWrapper", "contentWrapperProps", "trustedHosts"])
  ], 64);
}
const zs = /* @__PURE__ */ b(js, [["render", Ks]]), qs = async ({
  builderContextValue: e,
  symbol: t
}) => {
  if (t != null && t.model && // This is a hack, we should not need to check for this, but it is needed for Svelte.
  (e != null && e.apiKey))
    return ve({
      model: t.model,
      apiKey: e.apiKey,
      apiVersion: e.apiVersion,
      ...(t == null ? void 0 : t.entry) && {
        query: {
          id: t.entry
        }
      }
    }).catch((n) => {
      P.error("Could not fetch symbol content: ", n);
    });
}, Js = g({
  name: "builder-symbol",
  components: { ContentVariants: zs, DynamicDiv: X },
  props: [
    "symbol",
    "attributes",
    "dynamic",
    "builderContext",
    "builderBlock",
    "builderComponents",
    "builderLinkComponent"
  ],
  data() {
    var e;
    return { contentToUse: (e = this.symbol) == null ? void 0 : e.content, filterAttrs: V };
  },
  mounted() {
    this.setContent();
  },
  watch: {
    onUpdateHook0: {
      handler() {
        this.setContent();
      },
      immediate: !0
    }
  },
  computed: {
    blocksWrapper() {
      return "div";
    },
    contentWrapper() {
      return "div";
    },
    className() {
      var e, t;
      return [
        this.attributes[_()],
        "builder-symbol",
        (e = this.symbol) != null && e.inline ? "builder-inline-symbol" : void 0,
        (t = this.symbol) != null && t.dynamic || this.dynamic ? "builder-dynamic-symbol" : void 0
      ].filter(Boolean).join(" ");
    },
    onUpdateHook0() {
      return {
        0: this.symbol
      };
    }
  },
  methods: {
    setContent() {
      this.contentToUse || qs({
        symbol: this.symbol,
        builderContextValue: this.builderContext
      }).then((e) => {
        e && (this.contentToUse = e);
      });
    }
  }
});
function Gs(e, t, n, o, r, s) {
  var l, a, u, h, m;
  const i = k("ContentVariants");
  return c(), p("div", C({ class: e.className }, { ...e.filterAttrs(e.attributes, "v-on:", !1) }, x(e.filterAttrs(e.attributes, "v-on:", !0), !0)), [
    B(i, {
      nonce: e.builderContext.nonce,
      isNestedRender: !0,
      apiVersion: e.builderContext.apiVersion,
      apiKey: e.builderContext.apiKey,
      context: {
        ...e.builderContext.context,
        symbolId: (l = e.builderBlock) == null ? void 0 : l.id
      },
      customComponents: Object.values(e.builderComponents),
      data: {
        ...(a = e.symbol) == null ? void 0 : a.data,
        ...e.builderContext.localState,
        ...(h = (u = e.contentToUse) == null ? void 0 : u.data) == null ? void 0 : h.state
      },
      canTrack: e.builderContext.canTrack,
      model: ((m = e.symbol) == null ? void 0 : m.model) ?? "",
      content: e.contentToUse,
      linkComponent: e.builderLinkComponent,
      blocksWrapper: e.blocksWrapper,
      contentWrapper: e.contentWrapper
    }, null, 8, ["nonce", "apiVersion", "apiKey", "context", "customComponents", "data", "canTrack", "model", "content", "linkComponent", "blocksWrapper", "contentWrapper"])
  ], 16);
}
const Ys = /* @__PURE__ */ b(Js, [["render", Gs]]);
export {
  jn as A,
  ft as B,
  wo as C,
  Kn as D,
  ve as E,
  $o as F,
  Zs as G,
  ii as H,
  No as I,
  Uo as S,
  R as T,
  ds as V,
  Ce as _,
  ri as a,
  ai as b,
  D as c,
  Se as d,
  b as e,
  Y as f,
  ko as g,
  si as h,
  _ as i,
  de as j,
  At as k,
  Ys as l,
  ur as m,
  H as n,
  zs as o,
  E as p,
  qe as q,
  on as r,
  ni as s,
  sn as t,
  _s as u,
  Je as v,
  Fs as w,
  ei as x,
  ti as y,
  oi as z
};
